<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar Vuelo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>

            <v-layout row>
                  <v-flex xs12 sm6>
                   <label class="col-sm-6 control-label ">
                      {{$t('lang.flight.number')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="true"
                    id="flightnumber"
                    v-model="flightnumber"
                    v-validate="'required'"
                    name="flightnumber"
                    v-bind:placeholder="$t('lang.flight.number')">
                   </v-text-field>
                    </v-flex>
                   <v-flex xs12 sm6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.airlines.name')}}
                    </label>
                        <v-select class="form-control" id="title" v-validate="'required'"
                        v-model="airline"
                        name="airlineselect" :items="allAirlines" item-value = "id" item-text="name" solo></v-select>
                        </v-flex>
                </v-layout>
               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
-->             <v-layout row>
                  <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      {{$t('lang.flight.origin')}}
                   </label>
                   <v-select
                           v-model="origin"
                           v-validate="'required'"
                           name="origineselect" :items="allAirports" item-value = "id" :item-text="item => item.city +' ('+item.icaoCode+')'" solo>
                   </v-select>
                 </v-flex>
                   <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      Terminal
                   </label>
                   <v-select
                           v-model="terminal"
                           v-validate="'required'"
                           name="terminalselect" :items="filteredTerminals" item-value = "id" item-text="name" solo>
                   </v-select>
                 </v-flex>
                <!--<span>Selected: {{ origin }}</span>-->
                 <v-flex xs12 sm4>
                <label class="col-sm-12 control-label ">
                      {{$t('lang.flight.destiny')}}
                   </label>
                   <v-select
                           v-model="destiny"
                           v-validate="'required'"
                           name="destinyeselect" :items="allAirports" item-value = "id" item-text="name" solo>
                   </v-select>
                  </v-flex>
                  </v-layout>

                      <!--Departure date and time-->
                 <v-layout row>
                      <!--Departure date picker-->
                  <v-flex xs12 sm6>
                   <v-menu
                    ref="departure_menu"
                    v-model="departure_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="departure_date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        label="Fecha de Salida"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="departurepicker"
                      no-title
                      v-model="departure_date"
                      :min="new Date().toISOString().substr(0, 10)"
                      @change="departure_date_save"
                    ></v-date-picker>
                  </v-menu>
                 </v-flex>
                  <v-spacer></v-spacer>
                      <!--Departure time picker-->
                  <v-flex xs12 sm6>
                    <v-dialog
                        ref="dialogTD"
                        v-model="modal"
                        :return-value.sync="timeDeparture"
                        persistent
                        width="290px"
                      >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeDeparture"
                        label="Hora de Salida"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"

                      >
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal"
                      v-model="timeDeparture"
                      full-width
                      @change="departure_time_save"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                  </v-flex>
                 </v-layout>
                  <!--Arrive date AND TIME picker-->
                 <v-layout row>
                  <!--Arrive date picker-->
                  <v-flex xs12 sm6>
                    <v-menu
                        ref="arrive_menu"
                        v-model="arrive_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="arrive_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            label="Fecha de Llegada"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="arrivepicker"
                          no-title
                          v-model="arrive_date"
                          :min="departure_date"
                          @change="arrive_date_save"
                        ></v-date-picker>
                      </v-menu>
                     </v-flex>
                  <!--Arrive time picker-->
                  <v-flex xs12 sm6>
                   <v-dialog
                        ref="dialogTA"
                        v-model="modal2"
                        :return-value.sync="timeArrive"
                        persistent
                        width="290px"
                   >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeArrive"
                        label="Hora de Llegada"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                     </v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeArrive"
                      full-width
                      :min = "minTimeArrive"
                      @change="arrive_time_save"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
      </v-dialog>
                  </v-flex>
                 </v-layout>
            <v-layout row>
                <v-flex xs12 sm12>
                   <label class="col-sm-6 control-label ">
                      {{$t('lang.flight.capacity')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="false"
                    id="flightcapacity"
                    v-model="capacity"
                    v-validate="'required'"
                    name="flightcapacity"
                    v-bind:placeholder="$t('lang.flight.capacity')">
                   </v-text-field>
                   </v-flex>
                </v-layout>
            <v-layout row>
                  <v-flex xs12>
                <label class="col-sm-6 control-label ">
                  {{$t('lang.persons.remarks')}}
               </label>
                  <v-textarea
                    solo
                    id="remarks"
                    v-model="remarks"
                    name="remarks">
                 </v-textarea>
                    </v-flex>
            </v-layout>
       <v-btn color="primary" @click= "editFlight" >
           <v-icon medium class="mr-2">
            mdi-save
           </v-icon>
           {{$t('lang.flight.sbutton')}}
       </v-btn>

            </v-container>
           </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
                    <v-dialog v-model="errorAlert" max-width="500px">
                 <v-card color="red" dark>
                  <v-card-title >Error</v-card-title>
                  <v-card-text color="white">{{graphqlError}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="errorAlert = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { UPDATE_FLIGHT_MUTATION } from '../constants/graphql'
import { ALL_TERMINALS_QUERY } from '../constants/graphql'
export default {
    name: 'EditFlight',
    data() {
        return {
            titulo:"AGREGAR",
                arrive_menu: false,
                flightnumber: '',
                airline: '',
                departure_date: null,
                arrive_date: null,
                remarks: '',
                departure_menu: false,
                capacity: 50,
                destiny:'',
                origin:'',
                timeDeparture: null,
                menu: false,
                modal: false,
                timeArrive: null,
                menu2: false,
                modal2: false,
                minTimeArrive: null,
                arriveDateTime: null,
                departureDateTime: null,
                flightById: null,
                id:null,
                terminal:'',
                allTerminals:[],
                graphqlError: "",
                errorAlert: false,
               }
            },
    apollo: {

    allAirports: gql`query {
      allAirports {
        id
        name
        city
        country
        iataCode
        icaoCode
      }
    }`,
    allAirlines: gql`query {
      allAirlines {
        id
        name
        iata
        icao
      }
    }`,
  },
  async mounted(){
        await this.$apollo.query({
            query: ALL_TERMINALS_QUERY,
        }).then((data)=>{
          this.allTerminals = data.data.allTerminals
        })



      },
    computed: {
     filteredTerminals () {
      //return this.allTerminal
      console.log(origin)
      return this.allTerminals.filter(Terminal => Terminal.aeropuerto.id == this.origin)
    },
    },
  async created(){
  const flight = await this.$apollo.query({
        query: gql`query ($id: ID!) {
            flightById(id:$id) {
              id
              flightNumber
              airline{
                id
                name
                iata
              }
              sourceAirport{
                id
                name
                city
                country
                iataCode
                icaoCode
              }
              destinyAirport{
                id
                name
                city
                country
                iataCode
                icaoCode
              }
              dateDeparture
              dateArrive
              remarks
              isEditable
              capacity
              terminal{
              id,
              name
              }
            }
        }`,variables: {
          id: this.$route.params.id,
        }
    })
    this.flightById = flight.data.flightById
    console.log(this.flightById)
    this.id = flight.data.flightById.id
    this.flightnumber= this.flightById.flightNumber
    this.airline= this.flightById.airline.id
    this.departure_date= this.flightById.dateDeparture.substr(0,10)
    this.arrive_date= this.flightById.dateArrive.substr(0,10)
    this.remarks=this.flightById.remarks
    this.capacity= this.flightById.capacity
    this.destiny=this.flightById.destinyAirport.id
    this.origin=this.flightById.sourceAirport.id
    this.timeDeparture= this.flightById.dateDeparture.substr(11,5)
    this.timeArrive= this.flightById.dateArrive.substr(11,5)
    this.terminal = this.flightById.terminal.id
  },
  watch: {
      arrive_menu (val) {
        val && setTimeout(() => (this.$refs.arrivepicker.activePicker = 'YEAR'))
      },
      departure_menu (val) {
        val && setTimeout(() => (this.$refs.departurepicker.activePicker = 'YEAR'))
      },
    },
  methods:{
    arrive_date_save (arrive_date) {
      if(this.arrive_date == this.departure_date)
        {
          this.minTimeArrive = this.timeDeparture
        }
        this.$refs.arrive_menu.save(arrive_date)
      },
      arrive_time_save (timeArrive) {
        this.$refs.dialogTA.save(timeArrive)
        this.arriveDateTime = this.arrive_date+'T'+timeArrive
      },
      departure_date_save (departure_date) {
        this.$refs.departure_menu.save(departure_date)
      },
      departure_time_save (timeDeparture) {
        this.$refs.dialogTD.save(timeDeparture)
        this.departureDateTime = this.departure_date+'T'+timeDeparture
      },
    editFlight(){
        if(this.arriveDateTime==null){
            this.arriveDateTime=this.arrive_date+'T'+this.timeArrive
        }
        if(this.departureDateTime==null){
            this.departureDateTime = this.departure_date+'T'+this.timeDeparture
        }
        const {
        id,
        flightnumber,
        airline,
        origin,
        destiny,
        departureDateTime,
        remarks,
        arriveDateTime,
        capacity,
        terminal
     } = this.$data
        this.$apollo.mutate({
        mutation: UPDATE_FLIGHT_MUTATION,
        variables: {
            id,
            flightnumber,
            airline,
            origin,
            destiny,
            departureDateTime,
            remarks,
            arriveDateTime,
            capacity,
            terminal
        }
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              this.graphqlError = error
              this.errorAlert=true
              // We restore the initial user input
            })
        this.$router
            .push('/newFlights')

    },
  },
 }
</script>