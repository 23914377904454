<template>
  <div>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="waitingList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :key = "tableKey"


        >
            <template v-slot:item.pax="{ item }">
                <td>{{ item.pax.passportNumber}}, {{item.pax.firstName}} {{item.pax.familyName}} {{item.pax.movil}}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                     <div >
                         <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="showDialogDetails(item)"
                      >
                      mdi-details
                      </v-icon>
                       <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="showBookDialog(item)"
                      >
                      mdi-file
                      </v-icon>
                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
         </v-card>
         <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Eliminacion</v-card-title>
                  <v-card-text>Desea Eliminar la Reserva?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
       <v-dialog v-model="dialogBook" max-width="500px">
        <v-card>
          <v-card-title>Alerta de Confirmacion de Reserva</v-card-title>
          <v-card-text>Desea Confirmar la Reserva?</v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogBook = false">Cancelar</v-btn>
            <v-btn color="primary" text @click="bookItem()">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
     <v-dialog v-model="dialogDetails" max-width="800px" max-height="800px" v-if="itemTo">
                <v-card>
                  <v-card-title>Detalles del Pasajero {{ itemTo.pax.firstName }} {{ itemTo.pax.familyName }}</v-card-title>
                    <v-card-text>Pasaporte: {{ itemTo.pax.passportNumber }}</v-card-text>
                    <v-card-text>Telefono: {{ itemTo.pax.phoneNumber }}</v-card-text>
                    <v-card-text>email: {{ itemTo.pax.email }}</v-card-text>
                    <v-card-text>Creado Por: {{ itemTo.pax.createdBy.username }}</v-card-text>
                    <v-card-text>Reservas:</v-card-text>
                    <v-card-text v-for="reserva in itemTo.pax.paxBookings" v-bind:key="reserva.id">Itinerario: {{ reserva.itinerary.code }}, Origen: {{ reserva.flight.sourceAirport.city }}, Destino: {{ reserva.flight.destinyAirport.city }}</v-card-text>
                    <!--<v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>

                    <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                    <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                    <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { WAITING_LIST } from '../constants/graphql'
import { UPDATE_WAITING_LIST_MUTATION } from '../constants/graphql'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'WaitingList',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
  data() {
      return {
            search: '',
            headers: [
          {text: 'Posicion', value: 'position'},
          { text: 'Pax', value: 'pax' },
          { text: 'Telefono', value: 'pax.phoneNumber' },
          { text: 'Destino', value: 'destino.city' },
          { text: 'Actions', value: 'actions' },
        ],
        allWaitingList:[],
        dialogDelete: false,
        dialogDetails: false,
        dialogBook: false,
        itemTo:'',
        tableKey:0,
        username: (JSON.parse(this.userStore.getUser)).username,
        loading: false,

     }
  },
  components: {
  },
  props: {
    reservas: {
      type: Array,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    waitingList () {
      //return this.reservas

      return this.allWaitingList.filter(reservas => reservas.isCanceled==false && reservas.isBooking==false)
    },

  },

async mounted () {
     this.loadWaitingList()
    },
  methods: {
   showDeleteDialog(item){
            this.itemTo = item
            this.dialogDelete = true

      },
      showDialogDetails(item){
            this.itemTo = item
            this.dialogDetails = true

      },
   deleteItem(){
        const index = this.waitingList.findIndex(itins => itins.id ==this.itemTo.id)
        console.log("updated_created>>"+index)
        if (index >= 0) this.waitingList.splice(index, 1)
        this.cancelarReserva(this.itemTo.id,true)
   },
   async cancelarReserva(id,cancel){
        this.$apollo.mutate({
        mutation: UPDATE_WAITING_LIST_MUTATION,
        variables: {
            id,
            cancel,
        }
        }).then(()=>{
           this.dialogDelete = false
           this.tableKey = this.tableKey+1
           //this.loadAllReservas()
        }
        )
   },

   showBookDialog(item){
            this.itemTo = item
            this.dialogBook = true

      },
   bookItem(){
        const index = this.waitingList.findIndex(itins => itins.id ==this.itemTo.id)
        console.log("Booked WL>>"+index)
        if (index >= 0) this.waitingList.splice(index, 1)
        this.bookReserva(this.itemTo.id,true,true)
   },
   async bookReserva(id,booked, canceled){
        this.$apollo.mutate({
        mutation: UPDATE_WAITING_LIST_MUTATION,
        variables: {
            id,
            booked,
            canceled
        }
        }).then(()=>{
           this.dialogBook = false
           this.tableKey = this.tableKey+1
           //this.loadAllReservas()
        }
        )
   },
   getListPosition(item){
      var count = this.allWaitingList.filter(reserva => reserva.id < item.id && reserva.destino.id == item.destino.id).lenght
      return count
   },
   async loadWaitingList(){
     this.loading = true
     var user = this.username
     await this.$apollo.query({
            query: WAITING_LIST,
            fetchPolicy: "network-only",
            variables: {
                user
            }
            }).then((data)=>{
            this.allWaitingList = data.data.allWaitingList
            this.loading = false
            })
   },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
