<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Reservas de Hotel</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addReserva"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>
    <CreateHostalBooking v-if = "createReserva" @hideform = "hideCreateForm"/>
    <ReservasList v-if = "showList" key="reservasKey"/>

  </div>
</template>

<script>

import {nextTick} from 'vue'
import ReservasList from '@/components/ReservasList'
import CreateHostalBooking from '@/components/CreateHostalBooking'


export default {
  name: 'AllReservas',
  components: {
    ReservasList,
    CreateHostalBooking
  },

  data () {
    return {
      createReserva : false,
      showList : true,
      buttonTag:"Agregar Reserva",
      airlineKey : 0,
      loading: true

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createReserva = false
           this.buttonTag = "Agregar Reserva de Hostal"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addReserva(){
      await nextTick()
      this.reservaKey = this.reservaKey + 1
      this.createReserva = !this.createReserva
      this.showList = !this.showList
      if(this.createReserva)
         this.buttonTag = "Mostrar Lista"
      else
         this.buttonTag = "Agregar Reserva de Hostal"
   },

   hideCreateForm(){
      console.log("Event Received")
      this.createReserva = !this.createReserva
      this.showList = !this.showList
      this.buttonTag = "Agregar Reserva de Hostal"
   }
  },
}
</script>
