<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Vuelo</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>

            <v-layout row>
            <v-flex xs11 sm6>
                       <label class="col-sm-6 control-label ">
                          {{$t('lang.flight.number')}}
                       </label>

                       <v-text-field
                            solo
                            autocomplete="true"
                            id="flightnumber"
                            v-model="flightnumber"
                            v-validate="'required|numeric'"
                            hint="Solo Numeros"
                            name="flightnumber"
                            @input="flightnumber = flightnumber.replace(/[^\d]/g,'')"
                            v-bind:placeholder="$t('lang.flight.number')">
                       </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.airlines.name')}}
                    </label>
                        <v-select class="form-control" id="title" v-validate="'required'"
                        v-model="airline"
                        name="airlineselect" :items="allAirlines" item-value = "id" item-text="name" solo @change="setAirlineCode"></v-select>
                        </v-flex>


                </v-layout>
               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
-->             <v-layout row>
                  <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      {{$t('lang.flight.origin')}}
                   </label>
                   <v-select
                           v-model="origin"
                           v-validate="'required'"
                           name="origineselect" :items="allAirports" item-value = "id" :item-text="item => item.city +' ('+item.icaoCode+')'" solo>
                   </v-select>
                 </v-flex>
                   <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      Terminal
                   </label>
                   <v-select
                           v-model="terminal"
                           v-validate="'required'"
                           name="terminalselect" :items="filteredTerminals" item-value = "id" item-text="name" solo>
                   </v-select>
                 </v-flex>
                 <v-flex xs12 sm4>
                <label class="col-sm-12 control-label ">
                      {{$t('lang.flight.destiny')}}
                   </label>
                   <v-select
                           v-model="destiny"
                           v-validate="'required'"
                           name="destinyeselect" :items="allAirports" item-value = "id" :item-text= "item => item.city +' ('+item.icaoCode+')'" solo>
                   </v-select>
                  </v-flex>
                  </v-layout>

                      <!--Departure date and time-->
                 <v-layout row>
                      <!--Departure date picker-->
                  <v-flex xs10 sm5>
                   <v-menu
                    ref="departure_menu"
                    v-model="departure_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="departure_date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        label="Fecha de Salida"
                        >

                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="departurepicker"
                      no-title
                      v-model="departure_date"
                      :min="new Date().toISOString().substr(0, 10)"
                      @change="departure_date_save"
                    ></v-date-picker>
                  </v-menu>
                 </v-flex>
                 <v-flex xs2 sm1>
                     <v-btn color="primary" @click="SearchFlight" >
                        <v-icon
                        medium
                        >
                            mdi-magnify
                        </v-icon>
                     </v-btn>
                 </v-flex>
                  <v-spacer></v-spacer>
                      <!--Departure time picker-->
                  <v-flex xs12 sm6>
                    <v-dialog
                        ref="dialogTD"
                        v-model="modal"
                        :return-value.sync="timeDeparture"
                        persistent
                        width="290px"
                      >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeDeparture"
                        label="Hora de Salida"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"

                      >
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal"
                      v-model="timeDeparture"
                      full-width
                      @change="departure_time_save"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                  </v-flex>
                 </v-layout>
                  <!--Arrive date AND TIME picker-->
                 <v-layout row>
                  <!--Arrive date picker-->
                  <v-flex xs12 sm6>
                    <v-menu
                        ref="arrive_menu"
                        v-model="arrive_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="arrive_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            label="Fecha de Llegada"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="arrivepicker"
                          no-title
                          v-model="arrive_date"
                          :min="departure_date"
                          @change="arrive_date_save"
                        ></v-date-picker>
                      </v-menu>
                     </v-flex>
                  <!--Arrive time picker-->
                  <v-flex xs12 sm6>
                   <v-dialog
                        ref="dialogTA"
                        v-model="modal2"
                        :return-value.sync="timeArrive"
                        persistent
                        width="290px"
                   >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timeArrive"
                        label="Hora de Llegada"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                     </v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeArrive"
                      full-width
                      :min = "minTimeArrive"
                      @change="arrive_time_save"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
              </v-dialog>
                  </v-flex>
                 </v-layout>
            <v-layout row>
                <v-flex xs12 sm6>
                   <label class="col-sm-6 control-label ">
                      {{$t('lang.flight.capacity')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="false"
                    id="flightcapacity"
                    v-model="capacity"
                    v-validate="'required'"
                    name="flightcapacity"
                    v-bind:placeholder="$t('lang.flight.capacity')">
                   </v-text-field>
                   </v-flex>
              <!--  <v-flex xs12 sm6>
                   <label class="col-sm-6 control-label ">
                      {{$t('lang.flight.repetir')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="false"
                    id="flightcapacity"
                    v-model="repetir"
                    v-validate="'required'"
                    name="flightweeks"
                    v-bind:placeholder="$t('lang.flight.repetir')">
                   </v-text-field>
                   </v-flex>-->
                </v-layout>
            <v-layout row>
                  <v-flex xs12 sm12>
                   <label class="col-sm-6 control-label ">
                      {{$t('lang.flight.proveedor')}}
                   </label>
                   <v-select class="form-control " id="providers" v-validate="'required'"
                            v-model="provider"
                            name="providerSelect" :items="allProviders" item-value = "id" item-text="firstName" solo>
                        </v-select>
                  </v-flex>
                </v-layout>
            <v-layout row>
                 <v-flex xs12 sm12>
                <label class="col-sm-6 control-label ">
                  {{$t('lang.persons.remarks')}}
               </label>
                  <v-textarea
                    solo
                    id="remarks"
                    v-model="remarks"
                    name="remarks">
                 </v-textarea>
               </v-flex>
            </v-layout>

            <v-layout row>
            <v-dialog v-model="alert" max-width="500px">
            <v-card
                color="green"
                dark
              >
                <v-card-text color="white">
                    <v-icon medium class="mr-2">
                        mdi-check-circle
                       </v-icon>
                    <span class="text-h6" >Vuelo Guardado</span>
                </v-card-text>
            </v-card>
           </v-dialog>
            <v-dialog v-model="errorAlert" max-width="500px">
                 <v-card color="red" dark>
                  <v-card-title >Error</v-card-title>
                  <v-card-text color="white">{{graphqlError}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="errorAlert = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
             <v-dialog v-model="searchFlight" max-width="500px">
                 <v-card>
                  <v-card-title v-if="searching">Buscando Vuelos</v-card-title>
                  <v-card-title v-else>Vuelo Encontrado</v-card-title>
                  <v-card-text v-if="flightData" color="white">Hora de LLegada: {{flightData.FLSDepartureDateTime.substr(11,5)}}</v-card-text>
                    <v-card-text v-if="flightData" color="white">Fecha de Llegada: {{flightData['FLSArrivalDateTime'].substr(11,5)}}</v-card-text>
                    <v-card-text v-if="flightData" color="white">Hora de LLegada: {{flightData['FLSArrivalDateTime'].substr(0,10)}}</v-card-text>
                     <v-progress-circular v-if="searching"
                      :size="70"
                      :width="8"
                      color="white"
                      indeterminate
                  ></v-progress-circular>
                  <v-card-actions>
                    <v-btn color="primary" text @click="searchFlight = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="usarFlight">Utilizar</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
        <template>
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-dialog
                transition="dialog-top-transition"
                max-width="400"
                v-model="routeDialog"
              >
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >Crear ruta para el Vuelo {{flightnumber}} del {{departure_date}}</v-toolbar>
                     <v-card-text v-if="ida">
                      <div class="text-h5 pa-4" >
                          Ruta de Ida
                      </div>
                    </v-card-text>
                      <v-card-text v-else>
                      <div class="text-h5 pa-4" >
                          Ruta de Regreso
                      </div>
                    </v-card-text>
                 <v-spacer> <v-divider inset></v-divider> </v-spacer>
                     <v-checkbox
                          v-model="ida"
                          label="Ida/Regreso"
                        ></v-checkbox>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="addFlightAndRoute"
                      >Salvar</v-btn>
                      <v-btn
                        text
                        @click.close="dialog.value = false"
                      >Salir</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
        </template>

                  <v-btn color="primary" @click= "showCreateRoute" >
                       <v-icon medium class="mr-2">
                        mdi-content-save-all
                       </v-icon>
                       {{$t('lang.flight.sbuttonroute')}}
                   </v-btn>
                   <v-spacer></v-spacer>
                   <v-btn color="primary" @click= "addFlight" >
                       <v-icon medium class="mr-2">
                        mdi-content-save-all
                       </v-icon>
                       {{$t('lang.flight.sbutton')}}
                   </v-btn>
                   <v-spacer></v-spacer>
                   <v-btn color="primary" @click= "addAndStayFlight" >
                       <v-icon medium class="mr-2">
                        mdi-content-save
                       </v-icon>
                       {{$t('lang.flight.s&cbutton')}}
                   </v-btn>
            </v-layout>
           </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { SELLER_LIST } from '../constants/graphql'
import axios from 'axios'
import { CREATE_FLIGHTS_MUTATION } from '../constants/graphql'
import { CREATE_RUTAS_MUTATION } from '../constants/graphql'
import { ALL_TERMINALS_QUERY } from '../constants/graphql'
export default {
    name: 'CreateFlight',
    data() {
        return {
            titulo:"AGREGAR",
                arrive_menu: false,
                flightnumber: '',
                airline: '',
                departure_date: null,
                arrive_date: null,
                remarks: '',
                departure_menu: false,
                capacity: 50,
                destiny:'',
                origin:'',
                timeDeparture: null,
                menu: false,
                modal: false,
                timeArrive: null,
                menu2: false,
                modal2: false,
                minTimeArrive: null,
                arriveDateTime: null,
                departureDateTime: null,
                alert: false,
                errorAlert: false,
                flightId: null,
                routeDialog: false,
                ida: true,
                flights : "",
                dateDeparture:null,
                origen : null,
                destino : "",
                stops : 0,
                name :  "",
                activa: true,
                equipaje:1,
                graphqlError: "",
                terminal:1,
                allTerminals:[],
                repetir: 1,
                rules: {
                  required: value => !!value || 'Required.',
                  min: v => v.length >= 8 || 'Min 8 characters',
                  emailMatch: () => (`The email and password you entered don't match`),
                },
                flightData:'',
                searchFlight:false,
                searching:false,
                allProviders:[],
                provider:'',
                airlineIata:'',
               }
            },
    async created(){
     if(this.$route.params.id != null){
      const flight = await this.$apollo.query({
            query: gql`query ($id: ID!) {
                flightById(id:$id) {
                  id
                  flightNumber
                  airline{
                    id
                    name
                    iata
                  }
                  sourceAirport{
                    id
                    name
                    city
                    country
                    iataCode
                    icaoCode
                  }
                  destinyAirport{
                    id
                    name
                    city
                    country
                    iataCode
                    icaoCode
                  }
                  dateDeparture
                  dateArrive
                  remarks
                  isEditable
                  capacity
                  terminal{
                    id
                    name
                  }
                }
            }`,variables: {
              id: this.$route.params.id,
            }
        })
        this.flightById = flight.data.flightById
        console.log(this.flightById)
        this.id = flight.data.flightById.id
        this.flightnumber= this.flightById.flightNumber
        this.airline= this.flightById.airline.id
        //this.departure_date= this.flightById.dateDeparture.substr(0,10)
        //this.arrive_date= this.flightById.dateArrive.substr(0,10)
        this.remarks=this.flightById.remarks
        this.capacity= this.flightById.capacity
        this.destiny=this.flightById.destinyAirport.id
        this.origin=this.flightById.sourceAirport.id
        this.timeDeparture= this.flightById.dateDeparture.substr(11,5)
        this.timeArrive= this.flightById.dateArrive.substr(11,5)
        this.terminal = this.flightById.terminal.id
       }
      },
  async mounted(){
        await this.$apollo.query({
            query: ALL_TERMINALS_QUERY,
        }).then((data)=>{
          this.allTerminals = data.data.allTerminals
        })
        this.loadProviders()


      },
    computed: {
     filteredTerminals () {
      //return this.allTerminal
      console.log(origin)
      return this.allTerminals.filter(Terminal => Terminal.aeropuerto.id == this.origin)
    },
    },
    apollo: {
    allAirports: gql`query {
      allAirports {
        id
        name
        city
        country
        iataCode
        icaoCode
      }
    }`,
    allAirlines: gql`query {
      allAirlines {
        id
        name
        iata
        icao
      }
    }`,
  },
  watch: {

    },
  methods:{
    async setAirlineCode(item)
     {
       var airline = await this.allAirlines.filter(airline => airline.id == item)
       this.airlineIata = airline[0].iata
       console.log(this.airlineIata)
     },
    async SearchFlight(){

        var airline = this.allAirlines.filter(airline => airline.id == this.airline)
        var origen = this.allAirports.filter(airport => airport.id == this.origin)
        var destino = this.allAirports.filter(airport => airport.id == this.destiny)
        console.log('https://timetable-lookup.p.rapidapi.com/TimeTable/'+origen[0].icaoCode+'/'+destino[0].icaoCode+'/'+this.departure_date.replace('-','').replace('-','')+'>>'+airline[0].iata+this.flightNumber)
        const options = {
          method: 'GET',
          url: 'https://timetable-lookup.p.rapidapi.com/TimeTable/'+origen[0].icaoCode+'/'+destino[0].icaoCode+'/'+this.departure_date.replace('-','').replace('-',''),
          params: {
            Airline: airline[0].iata,
            FlightNumber: this.flightnumber
          },
          headers: {
            'X-RapidAPI-Key': 'd3e6b42621msh8457df547be0d75p115a08jsn3b6877ebacbf',
            'X-RapidAPI-Host': 'timetable-lookup.p.rapidapi.com'
          }
        };
        var parseString = require('xml2js').parseString;
        try {
            this.searchFlight= true
            this.searching= true
            await axios.request(options)
            .then(response => {
                parseString(response.data, (err, result) => {
                  if(err) {
                   //Do something
                  } else {
                   this.searching = false
                   console.log(result);
                   console.log(response.data);
                   this.flightData = result['OTA_AirDetailsRS']['FlightDetails'][0]['$']

                  }
                });
              })
            }
        catch (error) {
            console.error(error);
        }
    },
    usarFlight(){
                    this.timeDeparture= this.flightData.FLSDepartureDateTime.substr(11,5)
                    this.timeArrive= this.flightData['FLSArrivalDateTime'].substr(11,5)
                    this.arrive_date= this.flightData['FLSArrivalDateTime'].substr(0,10)
                    this.departureDateTime = this.departure_date+'T'+this.timeDeparture
                    this.arriveDateTime = this.arrive_date+'T'+this.timeArrive
                    this.searchFlight=false

    },
    async loadProviders(){
     this.loading = true
     //var user = this.username
     await this.$apollo.query({
            query: SELLER_LIST,
            fetchPolicy: "network-only",
            }).then((data)=>{
            console.log(data.data.allUsers)
            this.allProviders= data.data.allUsers.filter(user => user.isActive == true)
             .filter(user => user.groups.filter(group => group.id == 7).length > 0)
             console.log(this.allProviders)
            this.loading = false
            })
   },
    hideAlert: function () {
      console.log('Hide')
      window.setInterval(() => {
        this.alert = false;
      }, 2000)
    },

    arrive_date_save (arrive_date) {
      if(this.arrive_date == this.departure_date)
        {
          this.minTimeArrive = this.timeDeparture
        }
        this.$refs.arrive_menu.save(arrive_date)
      },
      arrive_time_save (timeArrive) {
        this.$refs.dialogTA.save(timeArrive)
        this.arriveDateTime = this.arrive_date+'T'+timeArrive
      },
      departure_date_save (departure_date) {
        this.$refs.departure_menu.save(departure_date)
      },
      departure_time_save (timeDeparture) {
        this.$refs.dialogTD.save(timeDeparture)
        this.departureDateTime = this.departure_date+'T'+timeDeparture
      },
    addAndStayFlight(){
        this.addFlight(false)
        this.departure_date= null
        this.arrive_date= null

    },
    /*findDuplicateFlight(number, date){
      exist = False
      //this.$apollo.query
    },*/
    async addFlight(close=true){

        if(this.arriveDateTime==null){
            this.arriveDateTime=this.flightById.dateArrive
        }
        if(this.departureDateTime==null){
            this.departureDateTime = this.flightById.dateDeparture
        }

        this.departure_time_save(this.timeDeparture)
        this.arrive_time_save(this.timeArrive)
        const {
        flightnumber,
        airline,
        origin,
        destiny,
        departureDateTime,
        remarks,
        arriveDateTime,
        capacity,
        terminal,
        repetir,
        provider
     } = this.$data
     this.$apollo.mutate({
        mutation: CREATE_FLIGHTS_MUTATION,
        variables: {
            flightnumber,
            airline,
            origin,
            destiny,
            departureDateTime,
            remarks,
            arriveDateTime,
            capacity,
            terminal,
            repetir,
            provider
        }
        }).then(()=>{
            this.alert=true
            this.hideAlert()
             if(close)
              this.$router
              .push('/newFlights')
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              this.graphqlError = error
              this.errorAlert=true
              // We restore the initial user input
            })
    },

    showCreateRoute(){
            this.routeDialog=true
        },

    addFlightAndRoute(){

        if(this.arriveDateTime==null){
            this.arriveDateTime=this.flightById.dateArrive
        }
        if(this.departureDateTime==null){
            this.departureDateTime = this.flightById.dateDeparture
        }
        this.departure_time_save(this.timeDeparture)
        this.arrive_time_save(this.timeArrive)
        const {
        flightnumber,
        airline,
        origin,
        destiny,
        departureDateTime,
        remarks,
        arriveDateTime,
        capacity,
        terminal,
        repetir,
        provider
     } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_FLIGHTS_MUTATION,
        variables: {
            flightnumber,
            airline,
            origin,
            destiny,
            departureDateTime,
            remarks,
            arriveDateTime,
            capacity,
            terminal,
            repetir,
            provider
        }
        }).then((data)=>{
            this.alert=true
            this.hideAlert()
            this.createRuta(data)
        }).then(()=>{
        if(close)
        this.$router
            .push('/newFlights')
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              this.graphqlError = error
              this.errorAlert=true
              // We restore the initial user input
            })


    },

    createRuta(data){
        console.log(data)
        var flightArray = []
        const newFlight ={
                id:data.data.createFlights.flightList[0].id,
                iata:data.data.createFlights.flightList[0].airline.iata,
                number:data.data.createFlights.flightList[0].flightNumber,
                dateDeparture : data.data.createFlights.flightList[0].dateDeparture.substr(0,10),
                capacity: data.data.createFlights.flightList[0].capacity
            }
        flightArray.push(newFlight)
        this.flights = JSON.stringify(flightArray)
        this.dateDeparture = data.data.createFlights.flightList[0].dateDeparture
        this.origen = data.data.createFlights.flightList[0].sourceAirport.id
        this.destino = data.data.createFlights.flightList[0].destinyAirport.id
        this.stops = 0
        this.name = data.data.createFlights.flightList[0].airline.iata+newFlight.number.toString()+" "+data.data.createFlights.flightList[0].sourceAirport.icaoCode+"-"+data.data.createFlights.flightList[0].destinyAirport.icaoCode
        console.log(this.flights)
        console.log(this.name)
        this.addRuta()
        this.$router
            .push('/allRutas')
    },
    addRuta(){
        const {
        name,
        flights,
        dateDeparture,
        ida,
        destino,
        origen,
        stops,
        remarks,
        activa,
        equipaje,
        repetir
        } = this.$data
        this.$apollo.mutate({
        mutation: CREATE_RUTAS_MUTATION,
        variables: {
            name,
            flights,
            dateDeparture,
            ida,
            destino,
            origen,
            stops,
            remarks,
            activa,
            equipaje,
            repetir
        }
        }).then((data)=>{
            console.log("Ruta:")
            console.log(data)
            }).catch((error) => {
              // Error
              console.error(error)
              // We restore the initial user input
            })

    },
  },
 }
</script>