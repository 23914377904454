<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title>Lista de Paquetes</v-toolbar-title>
      <v-spacer></v-spacer>


      <v-btn color="accent" large @click.stop="addpkg">Agregar Paquete</v-btn>
       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "goback"
       >
       Atras
       </v-btn>
       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >Cerrar Envio
       </v-btn>
       <v-spacer></v-spacer>
       <v-btn
       color="red"
       @click= "confirmandbill"
       >Confirmar
       </v-btn>
   </v-app-bar>
         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
             :headers="headers"
            :items="publishedPosts"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            @click:row="selectpaquete"

        >
            <template v-slot:item.author.user.username="{ item }">
                <td>Nested slot works: {{ item.author.user.username }}</td>
            </template>

            <template v-slot:item.actions="{ item }">
                     <div class="text-truncate">
                      <v-icon
                        medium
                        class="mr-2"
                        @click="showDuplicarDialog(item)"
                      >
                      mdi-plus
                      </v-icon>
                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
   <v-dialog v-model="dialogDuplicar" max-width="500px">
                <v-card>
                  <v-card-title>Delete</v-card-title>
                  <v-card-text>Desea Duplicar el Paquete?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDuplicar = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="duplicar()">Duplicar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
   <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Eliminar</v-card-title>
                  <v-card-text>Desea Eliminar el Paquete?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
    </v-card>

    <ol class="post-list">
      <li class="post" v-for="post in publishedPosts" :key="post.title">
          <span class="post__title">
            <router-link :to="`/post/${post.slug}`">{{ post.title }}: {{ post.subtitle }}</router-link>
          </span>
          <span v-if="showAuthor">
            by <AuthorLink :author="post.author" />
          </span>
          <div class="post__date">{{ displayableDate(post.publishDate) }}</div>
        <p class="post__description">{{ post.metaDescription }}</p>
        <ul>
          <li class="post__tags" v-for="tag in post.tags" :key="tag.name">
            <router-link :to="`/tag/${tag.name}`">#{{ tag.name }}</router-link>
          </li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script>
import AuthorLink from '@/components/AuthorLink'

export default {
  name: 'PostList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'PostName', value: 'title' },
          { text: 'Autor', value: 'author.user.username' },
          {text: 'Action', value: 'actions'},
        ],
        dialogDelete: '',
        dialogDuplicar:''

     }
  },
  components: {
    AuthorLink,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    publishedPosts () {
      return this.posts.filter(post => post.published)
    }
  },
  methods: {
    displayableDate (date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(date))
    },
       selectpaquete: function(item) {
          if (confirm('Seleccionar este blog? ' + item.slug)) {
                //this.$emit('paqueteselect', subscr.id)
                this.$router.push('/post/'+ item.slug)
           }
        },

        addpkg: function() {
        },

        goback: function(){if(confirm("Desea cancelar este Pedido? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar este Pedido? Los datos no guardados se perderan.")) this.$router.push('/')},

        async confirmandbill(){

        },

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },

        showDeleteDialog(item) {
            this.itemToDelete = item
            this.dialogDelete = !this.dialogDelete
        },

         showDuplicarDialog(item) {
            this.itemToDelete = item
            this.dialogDuplicar = !this.dialogDuplicar
        },

        duplicar: function(){

            this.dialogDuplicar = false
          },

        deleteItem() {

            this.dialogDelete = false
        },

        checkdigit: function (eanCode) {
            // Check if only digits
            var ValidChars = "0123456789";
            for (var i = 0; i < eanCode.length; i++) {
                var digit = eanCode.charAt(i);
                if (ValidChars.indexOf(digit) == -1) {
                    return false;
                }
            }

            // Add five 0 if the code has only 8 digits
            //if (eanCode.length == 8 ) {
            //	eanCode = "00000" + eanCode;
            //}
            // Check for 12 digits otherwise
            if (eanCode.length != 12) {
                return false;
            }

            // Get the check number
            //originalCheck = eanCode.substring(eanCode.length - 1);
            //eanCode = eanCode.substring(0, eanCode.length - 1);

            // Add even numbers together
            var even = Number(eanCode.charAt(1)) +
                Number(eanCode.charAt(3)) +
                Number(eanCode.charAt(5)) +
                Number(eanCode.charAt(7)) +
                Number(eanCode.charAt(9)) +
                Number(eanCode.charAt(11));
            // Multiply this result by 3
            even *= 3;

            // Add odd numbers together
            var odd = Number(eanCode.charAt(0)) +
                Number(eanCode.charAt(2)) +
                Number(eanCode.charAt(4)) +
                Number(eanCode.charAt(6)) +
                Number(eanCode.charAt(8)) +
                Number(eanCode.charAt(10));

            // Add two totals together
            var total = even + odd;

            // Calculate the checksum
            // Divide total by 10 and store the remainder
            var checksum = total % 10;
            // If result is not 0 then take away 10
            if (checksum != 0) {
                checksum = 10 - checksum;
            }

            // Return the result
            //if (checksum != originalCheck) {
            //	return false;
            //}

            return checksum;
        },

        Facturar: function(){
            //this.localFactura.paquetes = this.LocalUserPacks;
            this.$router.push('/factura');

        },
        /*getdestpacks: function (destid) {
           getAPI.get('/listaEnviosDestCount/'+destid, { headers: { Authorization: `Bearer ${this.$store.state.accessToken}` } }) // proof that your access token is still valid; if not the
                 // axios getAPI response interceptor will attempt to get a new  access token from the server. check out ../api/axios-base.js getAPI instance response interceptor
                 .then(response => {
                     console.log('GetAPI successfully got the provincias')
                     this.$store.state.APIPaquetes = response.data // store the response data in store
                  })
                  .catch(err => { // refresh token expired or some other error status
                    console.log(err)
                  })
        },
        */

    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
