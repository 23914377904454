<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Hoteles</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addHotel"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>
    <CreateHotel v-if = "createHotel" @hideform = "hideCreateForm"/>
    <HotelList v-if="showList"  key="hotelesKey"/>

  </div>
</template>

<script>
import {nextTick} from 'vue'
import HotelList from '@/components/HotelList'
import CreateHotel from '@/components/CreateHotel'

export default {
  name: 'AllHotel',
  components: {
    HotelList,
    CreateHotel,
  },

  data () {
    return {
      createHotel : false,
      showList: true,
      buttonTag:"Agregar Hotel",
      hotelKey : 0

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createHotel = false
           this.buttonTag = "Agregar Hotel"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

   async  addHotel(){
      await nextTick()
      this.hotelKey = this.hotelKey + 1
      this.createHotel = !this.createHotel
      this.showList = !this.showList
      if(this.createHotel)
         this.buttonTag = "Mostrar Hoteles"
      else
         this.buttonTag = "Agregar Hotel"
   },
   hideCreateForm(){
      this.createHotel = false
      this.showList = true
      if(this.showList)
         this.buttonTag = "Agregar Hotel"
      else
         this.buttonTag = "Mostrar Hoteles"
   }
  },
}
</script>
