<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Aerolineas</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>

   </v-app-bar>
    <AirlinesList key="airlinesKey"/>

  </div>
</template>

<script>

import {nextTick} from 'vue'
import AirlinesList from '@/components/AirlinesList'


export default {
  name: 'AllAirlines',
  components: {
    AirlinesList,
  },

  data () {
    return {
      createAirline : false,
      buttonTag:"Agregar Aerolinea",
      airlineKey : 0,
      loading: true

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createAirline = false
           this.buttonTag = "Agregar Aerolinea"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addAirline(){
      await nextTick()
      this.airlineKey = this.airlineKey + 1
      this.createAirline = !this.createAirline
      if(this.createAirline)
         this.buttonTag = "Mostrar Lista"
      else
         this.buttonTag = "Agregar Aerolinea"
   }
  },
}
</script>
