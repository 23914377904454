import { defineStore } from "pinia";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    token: localStorage.getItem("token") || null,
    user: localStorage.getItem("user") || "",
    authStatus: localStorage.getItem("authStatus") || false,
    paxkey: localStorage.getItem("paxkey") || null,
  }),
  getters: {
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    isAuthenticated: state => !!state.token,
    getAuthStatus: (state) =>{
    return state.authStatus
    },
    getPaxkey: (state) => state.paxkey,
  },
  actions: {
    setPaxkey(paxkey){
        this.paxkey = paxkey;
        localStorage.setItem("paxkey", this.paxkey);
    },
    incPaxkey(){
        this.paxkey++;
        localStorage.setItem("paxkey", this.paxkey);
    },

    setToken(token) {
      this.token = token;

      // Save token to local storage
      localStorage.setItem("token", this.token);
    },

    removeToken() {
      this.token = null;
      localStorage.setItem("authStatus", false);
      // Delete token from local storage
      localStorage.removeItem("token");
    },
    setUser(user) {
      this.user = JSON.stringify(user);
      this.authStatus = true;
      // Save user to local storage
      localStorage.setItem("user", user);
      localStorage.setItem("username", user.username);
      localStorage.setItem("authStatus", true);
      if(this.getAuthStatus)
        console.log('localstorage user set to >>'+this.user)
    },
    removeUser() {
      this.user = null;
      this.authStatus = false;
      localStorage.setItem("authStatus", false);
      // Delete user from local storage
      localStorage.removeItem("user");
      console.log('Usuario LogOut >>'+ this.getAuthStatus)
    },

  },
});
