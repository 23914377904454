<template>
  <div>
      <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
        <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Reporte de Ventas</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>

   </v-app-bar>
      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="itineraryReport"
            :items-per-page="50"
            class="elevation-1"
            :loading="loading"
            @click:row="(item) => showDetails(item)"

        >

   </v-data-table>
 </v-card>
 <v-app-bar
      color="blue darken-4"
      dark
    >
      <v-toolbar-title>Itinerarios</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      <download-excel :data="filterItins" :export-fields="xlsFields" :name ="xlsName" stringifyLongNum:true>
        <v-btn
       color="primary"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-file-excel-box
       </v-icon>
           Exportar PNL
       </v-btn>

</download-excel>
   </v-app-bar>
         <v-card>
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="searchItin"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="itinHeaders"
            :items="filterItins"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="itinLoading"


        >
       <template v-slot:item.code="{ item }">
                <td><a v-bind:href="'https://adriitours.alwaysdata.net/api/download_reserva/'+item.id" target="_blank"> {{item.code}}</a></td>
       </template>
       <template v-slot:item.pasajeros="{ item }">
                <td>{{item.paxs[0]}} {{item.code}}</td>
       </template>
      <template v-slot:item.EditionStatus="{ item }">
              <v-chip :color="getColor(item.EditionStatus)">
                {{ item.EditionStatus}}
              </v-chip>
      </template>

   </v-data-table>

     </v-card>

 </div>
</template>

<script>
import gql from 'graphql-tag'
import { ITINERARY_REPORT } from '../constants/graphql'
export default {
  name: 'ItineraryReport',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Year/Month', value: 'month', sortable: false },
          { text: 'Itinerarios Confirmados', value: 'monthCount' },
          { text: 'Pasajeros Confirmados', value: 'paxCount' },
          { text: 'Tarifa Base', value: 'tarifaSum' },
          { text: 'Comision', value: 'comisionSum' },
        ],
        itineraryReport:[],
        loading:false,
        searchItin: '',
        itinHeaders: [
          { text: 'Itinerario', value: 'code' },
          {text:  'Pasajeros', value:'paxs'},
          { text: 'Primer vuelo', value: 'firstFlight' },
          { text: 'Fecha de salida', value: 'departureDate' },
          { text: 'Creado Por', value: 'username' },
          { text: 'Editable', value: 'EditionStatus' },
          { text: 'Eliminado', value: 'isDeleted' },
          { text: 'Fecha de Creacion', value: 'createdAt' },
          { text: 'Vendedor', value: 'seller' },
        ],
        allItineraries:[],
        filterbokings:[],
        filterItins:[],
        itinLoading: false,
        xlsName:"boletos.xls",
        xlsFields: {
          "Itinerario": "code",
          Pasajeros: "paxs",
          "Cantidad de Pax": "paxCount",
          "Primer Vuelo": "firstFlight",
          "Fecha de Salida": "departureDate",
          "Creado Por": "username",
          Estado: "EditionStatus",
          "Eliminado":"isDeleted",
          "Fecha de Creacion": "createdAt",
          "Vendedor": "seller",
          },
     }
  },
  components: {
  },

  async created (){
    this.itinLoading = true
    const filterItins = new Array()
      const Itineraries = await this.$apollo.query({

        query: gql`query {
          allItinerariesFromCurrentMonth {
          id
          code
  		  isDeleted
  		  dateCreated
  		  paxCount
          seller{
            id
            username
          }
  		  EditionStatus{
  		    id
  		    name
  		  }
  		  createdBy{
  		    id
            username
          }
          itinBookings{
            id
            pax{
              id
              firstName
              familyName
              civil{
              name
              id
              }
            }
            flight{
              id
              flightNumber
              airline{
                id
                iata
              }
              dateDeparture
              dateArrive
            }
            position
            ida
          }
      }
    }`,fetchPolicy: "network-only"
    })

    //console.log(pagesItineraries)
    //this.totalItineraries = pagesItineraries.data.itineraries.objSize
    //console.log(this.totalItineraries)
    this.allItineraries = Itineraries.data.allItinerariesFromCurrentMonth
    let filtrados = this.allItineraries.filter(itineraries =>itineraries.itinBookings.length > 0 && itineraries.isDeleted == 0)
        console.log("Este es la lista de itinerarios: "+this.allItineraries.length+">>"+filtrados.length)
        filtrados.forEach(itin => {
            const id = itin.id
            const code = itin.code
            var firstFlight = ''
            var departureDate = ''
            var paxs = new Array()
            const isDeleted = itin.isDeleted
            this.filterbokings = itin.itinBookings.filter(booking => booking.position == 0 && booking.ida == 1)

            if(this.filterbokings.length > 0){
                  this.filterbokings.forEach(booking => {
                        paxs.push(" "+booking.pax.civil.name+' '+booking.pax.firstName +" "+booking.pax.familyName)
                        var distinctPax = []
                        for (var i = 0; i < paxs.length; i ++)
                            if(!distinctPax.includes(paxs[i]))
                             distinctPax.push(paxs[i])
                        paxs = distinctPax
                  })
                  firstFlight = this.filterbokings[0].flight.airline.iata+this.filterbokings[0].flight.flightNumber
                  departureDate = this.filterbokings[0].flight.dateDeparture.substr(11,5) +' del '+this.filterbokings[0].flight.dateDeparture.substr(0,10)
                  //pax = paxs[0]//this.filterbokings[0].pax.firstName +" "+this.filterbokings[0].pax.familyName
           }
            const username = itin.createdBy.username
            const EditionStatus = itin.EditionStatus.name
            const createdAt = itin.dateCreated
            const seller = itin.seller.username
            const tmpitin ={
            id: id,
            code:code,
            EditionStatus:EditionStatus,
            firstFlight:firstFlight,
            departureDate:departureDate,
            username:username,
            isDeleted:isDeleted,
            paxs:paxs,
            createdAt:createdAt.substr(0,10),
            seller: seller,
            paxCount: itin.paxCount
        }
            filterItins.push(tmpitin)
        })
    console.log("Created")
    this.filterItins = filterItins
    this.itinLoading = false
  },
  async mounted () {
     this.loadItineraryReport()
    },
  methods: {
    async showDetails(item){
    let month = item.month.substr(5,)
    let year = item.month.substr(0,4)
    this.itinLoading = true
    const filterItins = new Array()
      const Itineraries = await this.$apollo.query({

        query: gql`query($year:Int, $month:Int) {
          allItinerariesByMonth(year:$year, month:$month) {
          id
          code
  		  isDeleted
  		  dateCreated
  		  paxCount
          seller{
            id
            username
          }
  		  EditionStatus{
  		    id
  		    name
  		  }
  		  createdBy{
  		    id
            username
          }
          itinBookings{
            id
            pax{
              id
              firstName
              familyName
              civil{
              name
              id
              }
            }
            flight{
              id
              flightNumber
              airline{
                id
                iata
              }
              dateDeparture
              dateArrive
            }
            position
            ida
          }
      }
    }`,fetchPolicy: "network-only",
    variables:{
       year:year,
       month:month
    }
    })

    //console.log(pagesItineraries)
    //this.totalItineraries = pagesItineraries.data.itineraries.objSize
    //console.log(this.totalItineraries)
    this.allItineraries = Itineraries.data.allItinerariesByMonth
    let filtrados = this.allItineraries.filter(itineraries =>itineraries.itinBookings.length > 0 && itineraries.isDeleted == 0)
        console.log("Este es la lista de itinerarios: "+this.allItineraries.length+">>"+filtrados.length)
        filtrados.forEach(itin => {
            const id = itin.id
            const code = itin.code
            var firstFlight = ''
            var departureDate = ''
            var paxs = new Array()
            const isDeleted = itin.isDeleted
            this.filterbokings = itin.itinBookings.filter(booking => booking.position == 0 && booking.ida == 1)

            if(this.filterbokings.length > 0){
                  this.filterbokings.forEach(booking => {
                        paxs.push(" "+booking.pax.civil.name+' '+booking.pax.firstName +" "+booking.pax.familyName)
                        var distinctPax = []
                        for (var i = 0; i < paxs.length; i ++)
                            if(!distinctPax.includes(paxs[i]))
                             distinctPax.push(paxs[i])
                        paxs = distinctPax
                  })
                  firstFlight = this.filterbokings[0].flight.airline.iata+this.filterbokings[0].flight.flightNumber
                  departureDate = this.filterbokings[0].flight.dateDeparture.substr(11,5) +' del '+this.filterbokings[0].flight.dateDeparture.substr(0,10)
                  //pax = paxs[0]//this.filterbokings[0].pax.firstName +" "+this.filterbokings[0].pax.familyName
           }
            const username = itin.createdBy.username
            const EditionStatus = itin.EditionStatus.name
            const createdAt = itin.dateCreated
            const seller = itin.seller.username
            const tmpitin ={
            id: id,
            code:code,
            EditionStatus:EditionStatus,
            firstFlight:firstFlight,
            departureDate:departureDate,
            username:username,
            isDeleted:isDeleted,
            paxs:paxs,
            createdAt:createdAt.substr(0,10),
            seller: seller,
            paxCount: itin.paxCount
        }
            filterItins.push(tmpitin)
        })
    console.log("Created")
    this.filterItins = filterItins
    this.itinLoading = false

    },
    getColor (status) {
        if (status == "CANCELADO") return 'red'
        else if (status == "CONFIRMED") return 'blue'
        else return 'grey'
      },
   async loadItineraryReport(){
     this.loading = true
     await this.$apollo.query({
            query: ITINERARY_REPORT,
            fetchPolicy: "network-only",
            })
            .then((data)=>{
             this.itineraryReport = data.data.itinerarySumByMonth
             this.loading = false
            })
   },

        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},





    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
