import { render, staticRenderFns } from "./TerminalList.vue?vue&type=template&id=21a56ba2&"
import script from "./TerminalList.vue?vue&type=script&lang=js&"
export * from "./TerminalList.vue?vue&type=script&lang=js&"
import style0 from "./TerminalList.vue?vue&type=style&index=0&id=21a56ba2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports