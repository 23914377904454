<template>
  <div>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="hoteles"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"


        >
   </v-data-table>
         </v-card>
  </div>
</template>

<script>
import { HOTEL_LIST } from '../constants/graphql'
export default {
  name: 'HotelList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Name', value: 'name' },
          { text: 'Direccion', value: 'addressStr' },
          { text: 'Categoria', value: 'category.name' },
          { text: 'Aeropuerto Cercano', value: 'ciudadAirport.name' },
          { text: 'Telefono', value: 'phone' },
          { text: 'Email', value: 'email' },

        ],
        flightbookings:[],
        flightInfo:'',
        loading:false,
     }
  },
  components: {
  },
  props: {
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    registeredBags () {
      return this.hoteles

      //return this.flights.filter(flights => flights.is_editable)
    },

  },

  async mounted () {
     this.loadAllHoteles()
    },

  methods: {
   async loadAllHoteles(){
     this.loading = true
     await this.$apollo.query({
            query: HOTEL_LIST,
            fetchPolicy: "network-only",
            })
            .then((data)=>{
             this.hoteles = data.data.allHoteles
             this.loading = false
            })
   },
   selectItem: function(item) {
      if (confirm('Seleccionar este Hotel? ' + item.name)) {
            //this.$emit('paqueteselect', subscr.id)
            this.$router.push('/flight/'+ item.passportNumber)
       }
    },


        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },



    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
