<template>
    <div class="d-flex flex-column justify-center align-center fill-height">
      <h3>Image Cropper using Vuetify and <a href="https://www.npmjs.com/package/vue-cropperjs" target="_blank">vue-cropper-js</a></h3>
      
      <input
        ref="filePickerField"
        type="file" accept="image/*"
        @change="launchCropper" 
        hidden
      />

      <div>

          <v-img id="text-img" :src="avatarImage"></v-img>

      </div>

      <v-btn class="mt-5"
        @click="$refs.filePickerField.click()"
      > Upload </v-btn>
        <v-btn class="mt-5" @click="recognize">recognize</v-btn>

      <image-cropper-dialog
        ref="cropperDialog"
        :chosenImage="chosenImage"
        @onReset="$refs.filePickerField.value = null"
        @onCrop="(croppedImage) => {
          avatarImage = croppedImage;
        }"
      />
    </div>
</template>

<script>
/* eslint-disable */
import { createWorker, PSM, OEM } from 'tesseract.js';

import ImageCropperDialog from './ImageCropperDialog.vue';
export default {
  name: 'subirImagen',
  components: {
    ImageCropperDialog,
  },
  data() {
    return {
      avatarImage: require("../assets/logoAdritours.jpeg"),
      chosenImage: null,
    }
  },
  methods: {
    async launchCropper(event) {
      if (!event) return;
      var file = event.target.files[0];
      this.chosenImage = await this.toBase64(file);
      this.$refs.cropperDialog.initCropper(file.type);
    },

    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    recognize: async () => {
      const img = document.getElementById('text-img');
      console.log(img);
      const worker = await createWorker({
          logger: m => console.log(m),
        });
      await worker.load();
      await worker.loadLanguage('eng');
      await worker.initialize('eng', OEM.LSTM_ONLY);
      await worker.setParameters({
        tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
      });
      const { data: { text } } = await this.worker.recognize(img);
      console.log(text);
    }
  }
}
</script>