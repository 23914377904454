<template>
  <div>
      <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="airportList"
            :items-per-page="50"
            :search="search"
            class="elevation-1"
            :loading="loading"


        >
            <template v-slot:item.actions="{ item }">
                     <div class="text-truncate" v-if="item.active==true">
                      <v-icon
                        medium
                        class="mr-2"
                        color="green"
                      >
                      mdi-check-circle
                      </v-icon>
                     </div>
                    <div class="text-truncate" v-else>
                      <v-icon
                        medium
                        class="mr-2"
                        color="red"
                        @click="activate(item)"
                      >
                      mdi-cancel
                      </v-icon>
                     </div>
               </template>

   </v-data-table>
         </v-card>
  </div>
</template>

<script>
import { UPDATE_AIRPORT_MUTATION } from '../constants/graphql'
import { ALL_AIRPORTSFULL } from '../constants/graphql'
export default {
  name: 'AirportList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Ciudad', value: 'city' },
          { text: 'Pais', value: 'country' },
          { text: 'Codigo', value: 'icaoCode' },
          { text: 'Activar', value: 'actions' },
        ],
        airportList:[],
        flightInfo:'',
        loading:false,

     }
  },
  components: {
  },
  props: {
    airports: {
      type: Array,
      required: false,
    },

    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch:{
    itineraries(n,o)
        {
            console.log(n.lenght+'>>'+o.lenght)
        }
  },
  computed: {
    /*loading(){
        if(this.airports.length>0)
            return false
         else
             return true
    },*/
    registeredBags () {
      return this.airports

      //return this.flights.filter(flights => flights.is_editable)
    },

  },
  async created (){

  },
  async mounted () {
     this.loadAllAirports()
    },
  methods: {
   async loadAllAirports(){
     this.loading = true
     await this.$apollo.query({
            query: ALL_AIRPORTSFULL,
            fetchPolicy: "network-only",
            }).then((data)=>{
            console.log(data.data.allAirportsFull)
            this.airportList = data.data.allAirportsFull
            this.loading = false
            })
   },
   activate: function(item) {
      if (confirm('Activar este Aeropuerto? ' + item.name)) {
          this.activarItem(item.id, true)
          item.active=true
       }
    },

    activarItem(airport,activa){
        this.$apollo.mutate({
        mutation: UPDATE_AIRPORT_MUTATION,
        variables: {
            airport,
            activa,
        }
        })
},


        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },



    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
