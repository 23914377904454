<template>
  <div class="container">
    <v-app>
    <app-navigation :key="$route.fullPath"></app-navigation>
        <v-main transition="slide-x-transition">
        <router-view>

      </router-view>
    </v-main>
    </v-app>
    </div>

</template>

<script>
//v-if="!$route.meta.hideNavbar"
import AppNavigation from '@/components//AppNavigation';
export default {

    name: 'App',
    data: () => ({
      isEditing: true
     }),
    created () {
            document.title = "Adriitours";
        },

    components: {
        AppNavigation,
    }
};
</script>
<style>
</style>