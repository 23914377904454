import gql from 'graphql-tag'

export const SITE_INFO = gql`
  query {
    site {
      id
      name
    }
  }
`

export const ITINERARY_REPORT = gql`
  query{
    itinerarySumByMonth{
      month
      monthCount
      paxCount
      comisionSum
      tarifaSum
    }
  }
`
export const SELLER_LIST = gql`
query AllUsers{
 allUsers{
    id
    username
    email
    isActive
    firstName
    lastName
    profile{
      fullname
      id
      bio
      movil
    }
    groups{
      id
      name
    }
    itinerarySet{
      id
      code
      paxCount
      EditionStatus{
        id
        name
      }
      isDeleted
    }
  }
 }`

export const ITIN_MONTH_COUNT = gql`
query{
allItinerariesFromCurrentMonth{
  id
  paxCount
}
}`

export const PAX_IMG = gql` query($ppnumber:String!){
passportImageByPp(ppnumber:$ppnumber){
  id
  logo
}
}`
export const PAX_BY_ID = gql` query{
paxByPp(pp:"M511870"){
id
firstName
  paxBookings{
    id
    position
    ida
    flight{
      id
      flightNumber
      airline{
        id
        iata
      }
    }
    itinerary{
      id
      code

    }
  }
}
}`

export const PASSENGER_LIST = gql` query{
allPassengers{
          id
          passportNumber
          familyName
          firstName
          dateBorn
          dateExpired
          dateCreated
          remarks
          email
          civil{
            id
            name
          }
          nacionality{
            id
            nationality
          }
          housePhone
          phoneNumber
          dateExpired
          createdBy {
            id
            firstName
            username
            lastName
          }

        }
    }`

export const NEW_FLIGHT_LIST = gql` query{
allNewFlights
        {
          id
          flightNumber
          provider{
          id
          firstName
          }
          flightBookings{
            id
            itinerary{
              id
              isDeleted
              EditionStatus{
              id
              name
              }
            }
            pax{
              id
              adultType{
                id
                code
              }
            }

          }
          airline{
            id
            name
            iata
          }
          sourceAirport{
            id
            name
            city
            country
            iataCode
            icaoCode
          }
          destinyAirport{
            id
            name
            city
            country
            iataCode
            icaoCode
          }
          terminal{
          name
          id
          }
          dateDeparture
          dateArrive
          remarks
          isEditable
          capacity
        }
    }`
export const HOTEL_LIST = gql` query{
 allHoteles{
          id
          name
          category{
           id
           name
          }
          ciudadAirport
          {
            id
            name
            city
            country
          }
          addressStr
          email
          phone
      }
    }`

export const RUTAS_LIST = gql` query{
 allRutas {
          id
          name
          dateDeparture
          ida
          flights
          tarifaBase
          agencyFee
          origen{
            city
            }
          destino{
            city
          }
          stops
          }
    }`

export const FLIGHT_BY_NUMBER = gql` query{
paxByPp(pp:"M511870"){
id
firstName
  paxBookings{
    id
    position
    ida
    flight{
      id
      flightNumber
      airline{
        id
        iata
      }
    }
    itinerary{
      id
      code

    }
  }
}
}`
export const USER_SIGNUP = gql`
  mutation ($username: String!, $email: String!, $password: String!) {
    createUser(username: $username, email: $email, password: $password) {
      user {
        id
        username
        }
    }
  }
`

export const USER_SINGIN = gql`
  mutation ($username: String!, $password: String!) {
    tokenAuth(username:$username, password:$password) {
	  token
        user{
          id
          username
          groups{
            id
            name
          }
        }
    }
  }
`
export const ALL_AIRPORTSFULL = gql`
  query AllAirportsFull{
  allAirportsFull {
        id
        name
        city
        active
        country
        iataCode
        icaoCode
      }
  }`

export const ALL_AIRLINESFULL = gql`
  query AllAirlinesFull{
      allAirlinesFull {
        id
        name
        iata
        icao
        active
      }
    }`
export const WAITING_LIST = gql`
query AllWaitingList($user:String!) {
 allWaitingList(user:$user){
          id
          isCanceled
          isBooking
          position
          destino {
            id
            city
            country
          }
          pax{
              id
              firstName
              familyName
              passportNumber
              phoneNumber
              email
              createdBy{
                id
                username
              }
              paxBookings{
                id
                itinerary{
                 id
                 code
                }
                flight{
                 id
                 flightNumber
                 sourceAirport{
                    id
                   city
                   country
                   iataCode
                 }
                 destinyAirport{
                   id
                   city
                   country
                   iataCode
                 }
                }
              }
            }
            }
  }`

export const ALL_HOSTAL_BOOKING = gql`
query AllHotelBookings{
 allHotelBookings{
          id
          activa
          dateIn
          dateOut
          hotel {
            id
            name
          }
		paxreservaSet{
            pax{
              firstName
              familyName
              passportNumber
            }



          }
          }
  }`

export const ALL_TERMINALS_QUERY= gql`
    query AllTerminales{
       allTerminals {
                   id
                   name
                   aeropuerto{
                    id
                    name
                  }
              }
      }`

export const ALL_TARIFAS_QUERY = gql`
    query AllTarifas{
       allTarifas {
                   id
                   name
                   precio
                   equipaje{
                   id
                   name
                   }
              }
      }`

export const ALL_ITINERARY_QUERY = gql`
  query AllItineraryQuery{
        allItineraries {
          id
          code
  		  remarks
  		  roundtrip
  		  EditionStatus{
  		    name
  		  }
  		  createdBy{
            username
            lastName
          }
          itinBookings{
            flight{
              flightNumber
              airline{
                name
                iata
              }
              sourceAirport{
                name
        		iataCode
              }
              dateDeparture
              destinyAirport{
                name
                city
                iataCode
              }
              dateArrive
            }
            pnr
            ticket
            position
            ida

          }
      }
    }`

export const CREATE_PAX_MUTATION = gql`
  mutation CreatePAxMutation(
                        $passport :String!,
                        $first_name :String!,
                        $last_name :String!,
                        $issue_date :String,
                        $born_date :String,
                        $remarks :String,
                        $issue_country :Int,
                        $title :String,
                        $email:String,
                        $adult_type: Int,
                        $civil: Int,
                        $passport_type: Int,
                        $genero: Int,
                        $nacionality: Int,
                        $movil: String,
                        $phone: String,
                        $expire_date: String,
                        $extraBagCount: Int,
                        $created_by: String,
                        ) {
    createPassenger(
        passportNumber: $passport,
        firstName: $first_name,
        familyName:  $last_name,
        createdBy: $created_by,
        dateIssue: $issue_date,
        dateBorn :$born_date,
        remarks :$remarks,
        issueCountry: $issue_country
        title:$title,
        email:$email,
        adultType:$adult_type,
        civil:$civil,
        passportType:$passport_type,
        genero:$genero,
        nacionality:$nacionality,
        movil:$movil,
        phoneNumber:$phone,
        dateExpire:$expire_date,
        extrabagcount:$extraBagCount,
    ) {passenger{
          id
          passportNumber
          familyName
          firstName
          dateBorn
          dateExpired
          dateCreated
          dateModified
          dateValid
          isEditable
          title
          remarks
          email
          extraBagCount,
          createdBy{
           id
           username
          }
          adultType{
            id
            name
            code
          }
          civil{
            id
            name
            code
          }
          passportType{
            id
            name
            code
          }
          genero{
            id
            name
            code
          }
          nacionality{
            id
            nationality
          }
          issueCountry{
            id
            enShortName
          }
          housePhone
          phoneNumber
          createdBy {
            id
            firstName
            username
            lastName
          }
    }
    }
  }`

  export const UPDATE_PAX_MUTATION = gql`
  mutation UpdatePAxMutation(
                        $id:ID!,
                        $passport :String!,
                        $first_name :String!,
                        $last_name :String!,
                        $issue_date :String,
                        $born_date :String,
                        $remarks :String,
                        $issue_country :Int,
                        $title :String,
                        $email:String,
                        $adult_type: Int,
                        $civil: Int,
                        $passport_type: Int,
                        $genero: Int,
                        $nacionality: Int,
                        $movil: String,
                        $phone: String,
                        $expire_date: String,
                        $extraBagCount:Int,
                        ) {
    updatePassenger(
        id:$id
        passportNumber: $passport,
        firstName: $first_name,
        familyName:  $last_name,
        dateIssue: $issue_date,
        dateBorn :$born_date,
        remarks :$remarks,
        issueCountry: $issue_country
        title:$title,
        email:$email,
        adultType:$adult_type,
        civil:$civil,
        passportType:$passport_type,
        genero:$genero,
        nacionality:$nacionality,
        movil:$movil,
        phoneNumber:$phone,
        dateExpire:$expire_date,
        extrabagcount:$extraBagCount,
    ) {passenger{
          id
          passportNumber
          familyName
          firstName
          dateBorn
          dateExpired
          dateCreated
          dateModified
          dateValid
          isEditable
          title
          remarks
          email
          extraBagCount
          adultType{
            id
            name
            code
          }
          civil{
            id
            name
            code
          }
          passportType{
            id
            name
            code
          }
          genero{
            id
            name
            code
          }
          nacionality{
            id
            nationality
          }
          issueCountry{
            id
            enShortName
          }
          housePhone
          phoneNumber
          createdBy {
            id
            firstName
            username
            lastName
          }
    }
    }
  }`
  export const UPLOAD_PP = gql`
  mutation UpdatePpMutation(
                        $pp: String!
                        $file: Upload!
                        ) {
  uploadPp(
        name:$pp,
        logo:$file,
        ){
        success
        errors
        }


  }`

  export const CREATE_FLIGHTS_MUTATION = gql`
  mutation CreateFlightsMutation(
                        $flightnumber :Int!,
                        $airline :Int!,
                        $departureDateTime :String!,
                        $arriveDateTime :String!,
                        $destiny :Int!,
                        $origin :Int!,
                        $capacity :Int!,
                        $remarks :String!,
                        $terminal: Int,
                        $repetir: Int,
                        $provider:Int
                        ) {
    createFlights(

        flightNumber:$flightnumber
        airline: $airline,
        capacity: $capacity
        dateArrive: $arriveDateTime
        dateDeparture: $departureDateTime
        destinyAirport: $destiny,
        sourceAirport :$origin,
        remarks:$remarks,
        terminal:$terminal,
        repetir:$repetir,
        provider:$provider
    ){
      flightList
       {
        id
        flightNumber
        airline {
            id
            name
            iata
            icao
            }
        capacity
        dateArrive
        dateDeparture
        destinyAirport{
                id
                name
                icaoCode
                }
        sourceAirport{
                id
                name
                icaoCode
                }
        remarks
        terminal{
         id
         name
        }
    }
    }
  }`

  export const UPDATE_FLIGHT_MUTATION = gql`
  mutation updateFlightMutation(
                        $id:ID!,
                        $flightnumber :Int,
                        $airline :Int,
                        $departureDateTime :String,
                        $arriveDateTime :String,
                        $destiny :Int,
                        $origin :Int,
                        $capacity :Int,
                        $remarks :String,
                        $terminal:Int) {
    updateFlight(
        id:$id
        flightNumber:$flightnumber
        airline: $airline,
        capacity: $capacity
        dateArrive: $arriveDateTime
        dateDeparture: $departureDateTime
        destinyAirport: $destiny,
        sourceAirport :$origin,
        remarks:$remarks,
        terminal:$terminal
    ) {flight{
        id
        flightNumber
        airline {
            id
            name
            }
        capacity
        dateArrive
        dateDeparture
        destinyAirport{
                id
                name
                }
        sourceAirport{
                id
                name
                }
        terminal{
        id
        name
        }
        remarks
    }
    }
  }`

  export const CREATE_ITINERARY_MUTATION = gql`
  mutation CreateItineraryMutation(
                        $remarks: String,
                        $user: String,
                        $equipaje:Int,
                        $handEquipaje:Int,
                        $hotel: Int,
                        $roundtrip: Boolean,
                        $paxCount: Int,
                        $rutaIda:Int,
                        $rutaRegreso:Int,
                        $tarifa: Float,
                        $fee: Float,
                        $seller:Int
                        ) {
    createItinerary(
          rutaIda:$rutaIda,
          rutaRegreso:$rutaRegreso,
          remarks:$remarks,
          createdBy:$user,
          equipaje:$equipaje,
          hEquipaje:$handEquipaje,
          hotel: $hotel,
          roundtrip: $roundtrip,
          paxcount: $paxCount,
          fee: $fee,
          tarifa:$tarifa,
          seller:$seller
    ) {
        itinerary{
            id
            roundtrip
            paxCount
            rutaIda
            rutaRegreso
            seller{
            id
            firstName
            }
            hotel{
                id
            }
            createdBy{
                id
            }
            equipaje{
                id
            }
            hequipaje{
                id
            }
    }
    }
  }`

  export const UPDATE_ITINERARY_MUTATION = gql`
  mutation UpdateItineraryMutation(
                        $itinerary: ID!
                        $EditionStatus: Int
                        $PaymentStatus: Int
                        $Status: Int
                        $isEditable: Boolean
                        $paxCount: Int
                        $remarks: String
                        $roundtrip:Boolean
                        $code:String
                        $isDeleted:Boolean
                        $equipaje:Int
                        $handEquipaje:Int


                        ) {
    updateItinerary(
        id: $itinerary,
        editionStatus: $EditionStatus,
        paymentStatus: $PaymentStatus,
        status: $Status,
        isEditable: $isEditable,
        paxCount: $paxCount,
        remarks:$remarks,
        roundtrip:$roundtrip,
        code:$code,
        isDeleted:$isDeleted,
        equipaje:$equipaje
        hEquipaje:$handEquipaje
    ) {itinerary{
        id
        code
        EditionStatus{
         id
        }
    }
    }
  }`
  export const CREATE_BOOKING_MUTATION = gql`
  mutation CreateBookingMutation(
                        $flight: Int!
                        $isEditable: Boolean
                        $itinerary: Int!
                        $passenger: Int!
                        $pnr: String
                        $position: Int
                        $ida: Boolean
                        $status: Int
                        $ticket: String
                        $bag: Int
                        ) {
    createBooking(
            flight: $flight
            isEditable: $isEditable
            itinerary: $itinerary
            passenger: $passenger
            pnr: $pnr
            position: $position
            ida: $ida
            status: $status
            ticket: $ticket
            bag:$bag
    ) {booking{
        id
        position
        ida
    }
   }
  }`

  export const UPDATE_BOOKING_MUTATION = gql`
  mutation UpdateBookingMutation(
                        $booking: ID!
                        $flight: Int
                        $isEditable: Boolean
                        $itinerary: Int
                        $passenger: Int
                        $pnr: String
                        $position: Int
                        $ida: Boolean
                        $status: Int
                        $ticket: String
                        $isDeleted:Boolean
                        $bag:Int
                        ) {
    updateBooking(
            id:$booking
            flight: $flight
            isEditable: $isEditable
            itinerary: $itinerary
            passenger: $passenger
            pnr: $pnr
            position: $position
            ida: $ida
            status: $status
            ticket: $ticket
            isDeleted:$isDeleted
            bag:$bag
    ) {booking{
        id
        pnr
        ticket
        status{
            id
            name
        }
    }
    }
  }`

  export const CREATE_SELLER_MUTATION = gql`
  mutation CreateSellerMutation(
                        $profile: ProfileInfoInput!
                        ) {
    createSeller(
            input: $profile

    ) {user{
        id

    }
   }
  }`

  export const UPDATE_SELLER_MUTATION = gql`
  mutation UpdateSellerMutation(
                        $seller: ID!
                        $isValid: Boolean
                        $role: String
                        ) {
    updateSeller(
            id: $seller
            isActive: $isValid
            role: $role

    ) {
      user{
        id
        isActive
    }
   }
  }`
 export const CREATE_RUTAS_MUTATION = gql`
  mutation CreateRutasMutation(
                        $name: String!
                        $flights:String!
                        $dateDeparture:String!
                        $ida: Boolean
                        $activa: Boolean
                        $remarks: String
                        $destino: Int!
                        $origen: Int!
                        $stops:Int
                        $equipaje: Int
                        $repetir:Int
                        $tarifa: Float
                        $fee: Float
                        ) {
  createRutas(
        name: $name,
        flights: $flights,
        dateDeparture: $dateDeparture,
        ida :$ida,
        activa: $activa,
        remarks:$remarks,
        destino : $destino,
        origen:$origen,
        stops:$stops,
        equipaje:$equipaje,
        repetir:$repetir,
        tarifa:$tarifa,
        fee:$fee
        ) {
        rutaList{
        id
        name
    }
    }
  }`

export const CREATE_RUTA_MUTATION = gql`
  mutation CreateRutaMutation(
                        $name: String!
                        $flights:String!
                        $dateDeparture:String!
                        $ida: Boolean
                        $activa: Boolean
                        $remarks: String
                        $destino: Int!
                        $origen: Int!
                        $stops:Int
                        $equipaje: Int
                        $tarifa:Float
                        $fee:Float
                        ) {
  createRuta(
        name: $name,
        flights: $flights,
        dateDeparture: $dateDeparture,
        ida :$ida,
        activa: $activa,
        remarks:$remarks,
        destino : $destino,
        origen:$origen,
        stops:$stops,
        equipaje:$equipaje,
        tarifa:$tarifa,
        fee:$fee
        ) {
        ruta{
        id
        name
    }
    }
  }`

  export const UPDATE_RUTA_MUTATION = gql`
  mutation UpdateRutaMutation(
                        $id:ID!
                        $name: String!
                        $tarifa:Float
                        $fee:Float
                        ) {
  updateRuta(
        id:$id,
        name: $name,
        tarifa:$tarifa,
        fee:$fee
        ) {
        ruta{
        id
        name
    }
    }
  }`
 export const CREATE_BAG_MUTATION = gql`
  mutation CreateEquipajeMutation(
                        $name: String!
                        $hand: Boolean!
                        $unit: Int!
                        $weight: Int!
                        $quantity: Int!
                              ) {
    createEquipaje(
            name: $name
            hand: $hand
            unit: $unit
            quantity: $quantity
            weight: $weight
    ) {
    equipaje{
        id
        name
    }
   }
  }`

  export const CREATE_HOTEL_MUTATION = gql`
  mutation CreateHotelMutation(
                        $name: String!
                        $address: String!
                        $city: Int!
                        $category: Int!
                        $remarks: String!
                        $email: String
                        $phone: String
                              ) {
    createHotel(
            name: $name
            address: $address
            ciudad: $city
            category: $category
            remarks: $remarks
            email: $email
            phone: $phone
    ) {
    hotel{
        id
        name
    }
   }
  }`

 export const UPDATE_AIRPORT_MUTATION = gql`
  mutation UpdateBookingMutation(
                        $airport: ID!
                        $activa: Boolean
                        ) {
    updateAirport(
            id:$airport
            active: $activa
    ) {airport{
        id
        active
    }
    }
  }`

  export const UPDATE_AIRLINE_MUTATION = gql`
  mutation UpdateAirlineMutation(
                        $airline: ID!
                        $activa: String!
                        ) {
    updateAirline(
            id:$airline
            active: $activa
    ) {airline{
        id
        active
    }
    }
  }`

  export const CREATE_HOSTAL_BOOKING_MUTATION = gql`
  mutation CreateHostalBookingMutation(
                        $hotel: Int!
                        $dateArrive: String!
                        $dateDeparture: String!
                       ) {
    createHostalBooking(
            hotel: $hotel
            entrada: $dateArrive
            salida: $dateDeparture

    ) {
    hostalBooking{
        id
        hotel{
         id
         name
        }
        dateIn
        dateOut
    }
   }
  }`

  export const UPDATE_HOSTAL_BOOKING_MUTATION = gql`
  mutation updateHostalBooking(
                        $reserva: ID!
                        $activa: Boolean!
                        ) {
    updateHostalBooking(
            id:$reserva
            activa: $activa
    ) {
    hostalBooking{
        id
        activa
    }
    }
  }`
  export const CREATE_HOSTAL_PAX_BOOKING_MUTATION = gql`
  mutation CreateHostalPaxBookingMutation(
                        $pax: Int!
                        $booking: Int!
                        ) {
    createHostalPaxBooking(
            pax:$pax,
            booking:$booking
    ) {
    paxBooking{
        id
        pax{
          id
          firstName
          familyName
        }
        booking{
          id
        }
    }
   }
  }`

export const ADD_TO_WAITING_LIST_MUTATION = gql`
  mutation AddToWaitingListMutation(
                        $pax: Int!
                        $destino: Int!
                        $user:Int!
                        ) {
    addToWaitingList(
            pax:$pax,
            destino:$destino,
            createdBy:$user
    ) {
    waitingList{
        id
        pax{
          id
          firstName
          familyName
        }
        destino{
          id
        }

    }
   }
  }`
export const UPDATE_WAITING_LIST_MUTATION = gql`
  mutation UpdateWaitingListMutation(
                        $id:ID!
                        $cancel:Boolean
                        $book:Boolean
                        ) {
    updateWaitingList(
            id:$id
            canceled:$cancel
            booked:$book
    ) {
    waitingList{
        id
        isCanceled
        isBooking
        pax{
          id
          firstName
          familyName
        }
        destino{
          id
        }

    }
   }
  }`

 export const CREATE_TERMINAL_MUTATION = gql`
  mutation CreateTerminalMutation(
                        $name: String!
                        $aeropuerto: Int!
                        ) {
    createTerminal(
            name:$name,
            aeropuerto:$aeropuerto
    ) {
    terminal{
        id
        name
        aeropuerto{
          id
          name
        }
    }
   }
  }`

 export const CREATE_TARIFA_MUTATION = gql`
  mutation CreateTarifaMutation(
                        $name: String!
                        $precio: FLoat!
                        $equipaje: Int!
                        ) {
    createTarifa(
            name:$name,
            precio:$precio,
            equipaje:$equipaje
   ) {
    tarifa{
          id
          name
          precio
          equipaje{
            id
            name
          }
    }
   }
  }`