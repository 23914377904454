<template>
  <div>
    <FlightList  />
  </div>
</template>

<script>

import FlightList from '@/components/FlightList'

export default {
  name: 'AllNewFlights',
  components: {
    FlightList,
  },


}
</script>
