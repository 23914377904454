<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar Pasajero {{pax.id}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>

            <v-layout row>
                  <v-flex xs4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.id')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="passport"
                    @input="passport = passport.toUpperCase()"
                    v-validate="'required'"
                    name="ident"
                    v-bind:placeholder="$t('lang.persons.id')">
                   </v-text-field>
                    </v-flex>
                   <v-flex xs4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.id_type')}}
                    </label>
                        <v-select class="form-control" id="pptype"
                        v-model="passport_type"
                        name="pptypeselect" :items="allPassportTypes" item-value = "id" item-text="name" solo></v-select>
                        </v-flex>
                <v-flex xs4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.adultotype')}}
                    </label>
                        <v-select class="form-control" id="adult"
                        v-model="adult_type"
                        name="adultselect" :items="allAdultTypes" item-value = "id" item-text="name" solo></v-select>
                        </v-flex>
                </v-layout>
               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
-->             <v-layout row>
                  <v-flex xs6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.first_name')}}
                   </label>
               <v-text-field
                    @input="first_name = first_name.toUpperCase()"
                    solo
                    id="first_name"
                    v-model="first_name"
                    v-validate="'required'"
                    name="first_name">
               </v-text-field>
                    </v-flex>
                 <v-flex xs6>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.last_name')}}
               </label>
                 <v-text-field

                    solo
                    id="last_name"
                    v-model="last_name"
                    @input="last_name = last_name.toUpperCase()"
                    v-validate="'required'"
                    name= "last_name">
                 </v-text-field>
                    </v-flex>
                  </v-layout>
            <v-layout row>
                  <v-flex xs6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.EstadoCivil')}}
                   </label>
                        <v-select class="form-control" id="civil"
                        v-model="civil"
                        name="civilselect" :items="allCivils" item-value = "id" item-text="name" solo></v-select>
                    </v-flex>
                   <v-flex xs6>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.genero')}}
                    </label>
                        <v-select class="form-control" id="genero"
                        v-model="genero"
                        name="generoselect" :items="allGeneros" item-value = "id" item-text="name" solo></v-select>
                        </v-flex>
                </v-layout>
                  <v-layout row>
                  <v-flex xs4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.born_date')}}
                   </label>
                <v-menu
                    ref="born_menu"
                    v-model="born_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="born_date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="pickerBorn"
                      no-title
                      v-model="born_date"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="born_date_save"
                    ></v-date-picker>
                  </v-menu>
                 </v-flex>
                  <v-flex xs4>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.id_issue_date')}}
                </label>
            <v-menu
                ref="issue_menu"
                v-model="issue_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="issue_date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="pickerIssue"
                  no-title
                  v-model="issue_date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="issue_date_save"
                ></v-date-picker>
              </v-menu>
             </v-flex>
                  <v-flex xs4>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.id_expire_date')}}
                </label>
            <v-menu
                ref="expire_menu"
                v-model="expire_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="expire_date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="pickerValid"
                  no-title
                  v-model="expire_date"
                  :min="new Date().toISOString().substr(0, 10)"
                  @change="expire_date_save"
                ></v-date-picker>
              </v-menu>
             </v-flex>
            </v-layout>
            <v-layout row>
             <v-flex xs6>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.pais.issue')}}
                </label>
                    <v-select class="form-control" id="issueCountry"
                    v-model="issue_country"
                    v-bind:placeholder="$t('lang.address.localidadPH')"
                    name="issuecountries" :items="allCountries" item-value = "id" item-text="enShortName" solo></v-select>
                    </v-flex>
             <v-flex xs6>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.pais.nacionalidad')}}
                </label>
                    <v-select class="form-control" id="nacionalidad"
                    v-model="nacionality"
                    v-bind:placeholder="$t('lang.address.nacionalidad')"
                    name="nacionalidad" :items="allCountries" item-value = "id" item-text="nationality" solo></v-select>
                    </v-flex>
            </v-layout>
            <v-layout row>
                  <v-flex xs4>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.email')}}
                   </label>
                   <v-text-field
                    solo
                    autocomplete="off"
                    id="email"
                    v-model="email"
                    name="email"
                    v-bind:placeholder="$t('lang.persons.email')">
                   </v-text-field>
                    </v-flex>
                   <v-flex xs4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.movil')}}
                    </label>
                    <v-text-field
                    solo
                    autocomplete="off"
                    id="movil"
                    v-model="movil"
                    name="movil"
                    v-bind:placeholder="$t('lang.persons.movil')">
                   </v-text-field>
                  </v-flex>
                   <v-flex xs4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.phone')}}
                    </label>
                    <v-text-field
                    solo
                    autocomplete="off"
                    id="phone"
                    v-model="phone"
                    name="phone"
                    v-bind:placeholder="$t('lang.persons.phone')">
                   </v-text-field>
                  </v-flex>
                </v-layout>
            <v-layout row>
                  <v-flex xs12>
                <label class="col-sm-6 control-label ">
                  {{$t('lang.persons.remarks')}}
               </label>
                  <v-textarea
                    solo
                    id="remarks"
                    v-model="remarks"
                    name="remarks">
                 </v-textarea>
                    </v-flex>
            </v-layout>
       <v-btn color="primary" @click= "updatePax" ><v-icon medium class="mr-2">
            mdi-save
       </v-icon>
           {{$t('lang.persons.sbutton')}}
       </v-btn>

            </v-container>
           </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { UPDATE_PAX_MUTATION } from '../constants/graphql'

export default {
    name: 'EditPax',
    data() {
        return {
            titulo:"AGREGAR",
                issue_menu: false,
                passport: this.pax.passportNumber,
                first_name: this.pax.firstName,
                last_name: this.pax.familyName,
                born_date: this.pax.dateBorn == null ? null : this.pax.dateBorn.substr(0,10),
                issue_country: this.pax.issueCountry.id,
                issue_date: this.pax.dateExpired == null ? null : this.pax.dateExpired.substr(0,10),
                expire_date: this.pax.dateValid == null ? null : this.pax.dateValid.substr(0,10),
                expire_menu: false,
                remarks: this.pax.remarks,
                title: '',
                email: this.pax.email == null ? 'viajesadriitours@gmail.com' : this.pax.email,
                phone: this.pax.housePhone == null ? '+573167119076' : this.pax.housePhone,
                adult_type: this.pax.adultType.id,
                civil: this.pax.civil.id,
                passport_type: this.pax.passportType.id,
                genero: this.pax.genero.id,
                nacionality: this.pax.nacionality.id,
                movil: this.pax.phoneNumber == null ? '+57318796475' : this.pax.phoneNumber,
                born_menu: false,
                titles: ['CHILD','MISTER','MISSIS','INFANT'],
                id:this.pax.id,
                 }
            },
    apollo: {
    allCountries: gql`query {
      allCountries {
        id
        enShortName
        alpha3Code
        nationality
      }
    }`,
    allGeneros: gql`query {
      allGeneros {
        id
        name
        code
      }
    }`,
    allAdultTypes: gql`query {
      allAdultTypes {
        id
        name
        code
      }
    }`,

    allCivils: gql`query {
      allCivils {
        id
        name
        code
      }
    }`,
    allPassportTypes: gql`query {
      allPassportTypes {
        id
        name
        code
      }
    }`,
  },
  props: {
    pax: {
        type: Object,
    },
  },
  computed: {
    child: function(){
      if(this.$route.params.id > 0)
         return false
      else{
         return true
         }
    }
    },
  /*async created(){
    this.paxToEdit = await this.$apollo.query({
      query: gql`query ($id: ID!) {
        paxById(id:$id) {
          id
          passportNumber
          familyName
          firstName
          dateBorn
          dateExpired
          dateCreated
          dateModified
          dateValid
          isEditable
          title
          remarks
          email
          adultType{
            id
            name
            code
          }
          civil{
            id
            name
            code
          }
          passportType{
            id
            name
            code
          }
          genero{
            id
            name
            code
          }
          nacionality{
            id
            nationality
          }
          issueCountry{
            id
            enShortName
          }
          housePhone
          phoneNumber
          createdBy {
            id
            firstName
            username
            lastName
          }

        }
        }`,fetchPolicy: "network-only",
        variables: {
          id: this.paxId,
        },
    })

    console.log(this.paxById)
    this.id = this.paxById.id
        this.passport = this.paxById.passportNumber
        this.first_name= this.paxById.firstName
        this.last_name= this.paxById.familyName
        if(this.paxById.dateExpired != null)
            this.issue_date= this.paxById.dateExpired.substr(0,10)
        if(this.paxById.dateBorn != null)
            this.born_date = this.paxById.dateBorn.substr(0,10)
        this.remarks = this.paxById.remarks
        this.issue_country= this.paxById.issueCountry.id
        this.email= this.paxById.email == null ? 'viajesadriitours@gmail.com' : this.paxById.email
        this.adult_type= this.paxById.adultType.id
        this.civil= this.paxById.civil.id
        this.passport_type= this.paxById.passportType.id
        this.genero= this.paxById.genero.id
        this.nacionality= this.paxById.nacionality.id
        this.movil= this.paxById.phoneNumber == null ? '+57318796475' : this.paxById.phoneNumber
        this.phone= this.paxById.housePhone == null ? '+573167119076' : this.paxById.housePhone
        if(this.paxById.dateValid != null)
            this.expire_date= this.paxById.dateValid.substr(0,10)
  },*/
  watch: {
      born_menu (val) {
        val && setTimeout(() => (this.$refs.pickerBorn.activePicker = 'YEAR'))
      },
      expire_menu (val) {
        val && setTimeout(() => (this.$refs.pickerValid.activePicker = 'YEAR'))
      },
      issue_menu (val) {
        val && setTimeout(() => (this.$refs.pickerIssue.activePicker = 'YEAR'))
      },
    },
  methods:{
    expire_date_save (expire_date) {
        this.$refs.expire_menu.save(expire_date)
      },
    born_date_save (born_date) {
        this.$refs.born_menu.save(born_date)
      },
    issue_date_save (issue_date) {
        this.$refs.issue_menu.save(issue_date)
      },
    updatePax(){
        const {
        id,
        passport,
        first_name,
        last_name,
        issue_date,
        expire_date,
        born_date,
        remarks,
        issue_country,
        title,
        adult_type,
        civil,
        passport_type,
        genero,
        nacionality,
        movil,
        phone,
        email,
        } = this.$data
        this.$apollo.mutate({
        mutation: UPDATE_PAX_MUTATION,
        variables: {
            id,
            passport,
            first_name,
            last_name,
            issue_date,
            expire_date,
            born_date,
            remarks,
            issue_country,
            title,
            adult_type,
            civil,
            passport_type,
            genero,
            nacionality,
            movil,
            phone,
            email
        }
        }).then((data)=>{
            console.log(data)

        })
        if(this.child)
          this.$emit('hide',this.passport,this.last_name, this.first_name)
        else
          this.$router
            .go(-1)


    },
  },
 }
</script>