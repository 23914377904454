<template>
    <div id="app">
       <v-app-bar app clipped-left color="blue" dark>
           <div class="d-md-none">
                <v-btn icon @click.stop="drawer = !drawer">
                    <v-app-bar-nav-icon></v-app-bar-nav-icon>
                </v-btn>
            </div>
<!--
           <div class="text-center d-none d-md-block">
            <v-menu offset-y v-if="isLoggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  ITINERARIOS
                </v-btn>
              </template>
              <v-list>
                  <v-list-item to="/fastItin">
                    <v-list-item-title  class="toolbar-title">Itinerario Express</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/itineraries">
                    <v-list-item-title  class="toolbar-title">Itinerarios</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/allReservas">
                    <v-list-item-title  class="toolbar-title">Reserva de Hotel</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/newFlights">
                    <v-list-item-title  class="toolbar-title">Vuelos</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/allRutas">
                    <v-list-item-title  class="toolbar-title">Rutas</v-list-item-title>
                  </v-list-item>

              </v-list>
            </v-menu>
          </div>
          <v-divider vertical></v-divider>
          <div class="text-center d-none d-md-block">
            <v-menu offset-y v-if="isLoggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  DATOS
                </v-btn>
              </template>
              <v-list>

                <v-list-item to="/paxs">
                    <v-list-item-title  class="toolbar-title">Pasajeros</v-list-item-title>
                  </v-list-item>

                  <v-list-item to="/allItineraries">
                    <v-list-item-title  class="toolbar-title">Itinerarios</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/flights">
                    <v-list-item-title  class="toolbar-title">Vuelos</v-list-item-title>
                  </v-list-item>
              </v-list>
            </v-menu>
          </div>

    <v-divider vertical></v-divider>
          <div class="text-center d-none d-md-block">
            <v-menu offset-y v-if="isLoggedIn">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  NOMENCLADORES
                </v-btn>
              </template>
              <v-list>

                <v-list-item to="/allBags">
                    <v-list-item-title  class="toolbar-title">Equipajes</v-list-item-title>
                  </v-list-item>

                  <v-list-item to="/allHotels">
                    <v-list-item-title  class="toolbar-title">Hoteles</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/allAirports">
                    <v-list-item-title  class="toolbar-title">Aeropuertos</v-list-item-title>
                  </v-list-item>
                   <v-list-item to="/allTerminals">
                    <v-list-item-title  class="toolbar-title">Terminales</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/allAirlines">
                    <v-list-item-title  class="toolbar-title">Aerolineas</v-list-item-title>
                  </v-list-item>
              </v-list>
            </v-menu>
          </div>

            <v-spacer class="hidden-md-and-up"></v-spacer>
            -->
            <v-toolbar-title >
            </v-toolbar-title>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-toolbar-title class="ma-2" v-if="username_str">
               Bienvenido {{username_str}}
            </v-toolbar-title><v-divider vertical></v-divider>
           <!--<v-btn class="ma-2" color="green" v-if="username_str" @click="userSignOut">Salir</v-btn>-->

        </v-app-bar>
<v-navigation-drawer v-if="username_str"
            app
            clipped
            color="blue grey" dark
            expand-on-hover>
            <sideMenu></sideMenu>
      </v-navigation-drawer>
<v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      color="blue grey" dark
    >
       <sideMenu></sideMenu>
    </v-navigation-drawer>
    </div>

</template>

<script>
import { useUserStore } from "@/stores/user";
import {computed} from "vue";
import sideMenu from '@/components/sideMenu'
  export default {
    name: 'AppNavigation',
    components: {
      sideMenu
  },
      setup() {
        const userStore = useUserStore();
        const info  = computed(() => userStore.getUser);
        const token  = computed(() => userStore.getToken);
        const authStatus = computed(() => userStore.getAuthStatus);
        return { userStore, info,token, authStatus};
      },
    data () {
    return {
      drawer:false,
      user: this.info,
      isLoggedIn  : this.userStore.getAuthStatus || false,
      username_str: '',
      auth : this.authStatus,
      }
  },

  async created() {
/*    const siteInfo = await this.$apollo.query({
      query: SITE_INFO,
    });
    this.mySite = siteInfo.data.site;
*/
    if (this.token) {
      console.log("App User")
      console.log("getAuth>>"+this.auth)
       //console.log(JSON.parse(this.user))
       this.username_str = JSON.parse(this.user).username
    }
    else
      this.userSignOut()


  },

  methods: {
    userSignOut() {
      this.userStore.removeToken();
      this.userStore.removeUser();
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
</style>