<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
              <v-toolbar-title>Crear Itinerario</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.id')}}
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="passport"
                    @input="passport = passport.toUpperCase()"
                    v-validate="'required'"
                    name="ident"
                    v-bind:placeholder="$t('lang.persons.id')"
                    append-outer-icon="mdi-account-search"
                    @click:append-outer="searchPax"
                   >
                   </v-text-field>
                 </v-flex>
                  <v-flex xs12 sm6>
                    <label class="col-sm-12 control-label ">
                      {{$t('lang.persons.adultotype')}}
                    </label>
                        <v-select class="ma-2" id="adult"
                        v-model="adult_type"
                        name="adultselect" :items="allAdultTypes" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>-->
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.first_name')}}
                   </label>
               <v-text-field
                    class="ma-2"
                    @input="textInput"
                    solo
                    id="first_name"
                    v-model="first_name"
                    v-validate="'required'"
                    name="first_name">
                   @click="searchPax"
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.last_name')}}
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="last_name"
                    v-model="last_name"
                    @input="last_name = last_name.toUpperCase()"
                    v-validate="'required'"
                    name= "last_name">
                 </v-text-field>
            </v-flex>
              <v-flex xs11 sm4 class="pt-16 ">
               <v-btn color="primary" @click= "crearBtn" class="col-sm-11 mx-3">
                   <v-icon medium>
                    mdi-save
                   </v-icon>
                   {{$t('lang.persons.sbutton')}}
               </v-btn>
            </v-flex>
            </v-layout>
                <!--Tabla con la lista de pasajeros creados-->
            <v-layout row>
               <v-col xs12>
                <v-data-table
                 :headers="headers"
                 :items="paxs"
                 :items-per-page="10"
                 class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                             <div class="text-truncate">
                                <v-icon
                                medium
                                @click="editPax(item)"
                              >
                              mdi-pencil
                              </v-icon>
                              <v-icon
                                medium
                                class="mr-2"
                                @click="showDetailsDialog(item)"
                              >
                              mdi-details
                              </v-icon>
                              <v-icon
                                medium
                                color ="red"
                                @click="removePax(item)"
                              >
                              mdi-delete
                              </v-icon>
                          </div>
                  </template>
                </v-data-table>
               </v-col>
            </v-layout>

               <v-checkbox
                  v-model="roundTrip"
                  label="Itinerario de Ida y Vuelta"
                ></v-checkbox>
                <!--lista de rutas-->
                <v-col cols="12">
                <v-layout row >

                    <v-autocomplete

                      v-model="rutaIda"
                      :items="allRutasIda"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label="Seleccione la ruta Vuelos de Ida"
                      item-text="name"
                      item-value="id"
                      rounded
                      :menu-props="{closeOnContentClick:true}"
                    >
                     <template v-slot:selection="data">
                        <v-chip
                          color = "primary"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeIdaFlight(data.item)"
                        >
                          <v-avatar left>
                            <v-icon color="white" medium>mdi-airplane-takeoff</v-icon>
                          </v-avatar>
                          {{ data.item.name }} ({{icount}}/{{icapacity}} pax)
                        </v-chip>
                      </template>
                     <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content @click="routeSelected(data.item,1)">
                            <v-list-item-title >{{ data.item.name }}</v-list-item-title>
                            <v-list-item-subtitle >{{ data.item.dateDeparture.substring(0,10) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                     <template v-slot:append-outer>
                       <v-icon
                        color="blue"
                        @click="isEditing = !isEditing"
                        >mdi-plus</v-icon>
                    </template>
                    </v-autocomplete>
                </v-layout>
                </v-col>
                <v-layout row v-if="roundTrip">
                <v-col cols="12">
                    <v-autocomplete

                      v-model="rutaRegreso"
                      :items="allRutasRegreso"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label="Seleccione la ruta Vuelos de Regreso"
                      item-text="name"
                      item-value="id"
                      rounded
                      prepend-outer-icon="mdi-magnify"
                      :menu-props="{closeOnContentClick:true}"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          color = "primary"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeReturnFlight(data.item)"
                        >
                          <v-avatar left>
                              <v-icon color="white" medium>mdi-airplane-landing</v-icon>
                            <!--<v-img :src="data.item.avatar"></v-img>-->
                          </v-avatar>
                          {{ data.item.name }} ({{rcount}}/{{rcapacity}} pax)
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content @click="routeSelected(data.item,0)">
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                            <v-list-item-subtitle >{{ data.item.dateDeparture.substring(0,10) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                      <template v-slot:append-outer>
                       <v-icon
                        color="blue"
                        @click="isEditing = !isEditing"
                        >mdi-plus</v-icon>
                    </template>
                    </v-autocomplete>
                  </v-col>

                </v-layout>
              <!-- <v-layout row wrap>
                <v-flex xs12 sm6>
                <v-checkbox
                  v-model="taxFree"
                  label="TAX FREE"
                ></v-checkbox>
                <label class="control-label ma-2">
                      Tarifa Base: {{tarifa}}, Impuesto de Agencia: {{fee}}
                   </label>
                </v-flex>
                </v-layout>-->
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                   <label class="control-label ma-2 sm6">
                      {{$t('lang.ruta.equipaje')}}
                   </label>
                   <v-select class="ma-2 sm6" id="equipaje"
                        v-model="equipaje"
                        name="equipaje" :items="allEquipajesBodega" item-value = "id" item-text="name" solo>
                   </v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                   <label class="control-label ma-2 sm6">
                      {{$t('lang.ruta.hequipaje')}}
                   </label>
                   <v-select class="ma-2 sm6" id="equipaje"
                        v-model="handEquipaje"
                        name="equipaje" :items="allEquipajesMano" item-value = "id" item-text="name" solo>
                   </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row>
                <v-checkbox
                  v-model="conHotel"
                  label="Seleccionar Hospedaje"
                ></v-checkbox>
                <v-flex xs12 sm6 v-if="conHotelPost">
                   <v-select class="ma-2 sm6" id="hotel"
                        v-model="Hotel"
                        name="hotel" :items="cityHoteles" item-value = "id" item-text="name" solo>
                   </v-select>
                  </v-flex>
                </v-layout>
                <v-layout>
                <v-flex xs12 sm12 >
                    <label class="col-sm-10 control-label ">
                      DATOS DEL VENDEDOR(A)
                   </label>
                   <v-select class="ma-2 sm6" id="vendedorselect"
                        v-model="seller"
                        name="vendedorseller" :items="allSellerList" item-value = "id" item-text="firstName" solo>
                   </v-select>
                  </v-flex>
                </v-layout>
               <!--<v-layout row wrap>
                  <v-flex xs12 sm12>
                   <label class="col-sm-10 control-label ">
                      DATOS DEL VENDEDOR(A)
                   </label>
                    <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="vendedor"
                    v-model="vendedor"
                    @input="vendedor = vendedor.toUpperCase()"
                    v-validate="'required'"
                    name="seller"

                   >
                   </v-text-field>
                </v-flex>
            </v-layout>-->
            <v-layout row wrap>
               <v-btn color="primary" @click= "createItinerario" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   {{$t('lang.itinerary.sbutton')}}
               </v-btn>
                    <v-spacer></v-spacer>
               <v-btn color="red" @click= "gohome" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-close
                   </v-icon>
                   {{$t('lang.itinerary.sclose')}}
               </v-btn>
            </v-layout>
      </v-container>
 </v-card>
      </v-flex>
    </v-layout>
<v-dialog v-model="showEdit">
    <EditPax :pax="paxToEdit" @hide = "hideEdit" v-bind:key="update"/>
</v-dialog>
<v-dialog v-model="paxFound" max-width="500px">
                <v-card>
                  <v-card-title>Pasajero Encontrado</v-card-title>
                  <v-card-text>Pasaporte: {{passport}}</v-card-text>
                  <v-card-text>Nombre: {{first_name}} {{last_name}}</v-card-text>
                   <v-card-text>Reservas: {{bookingCount}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="hidePaxFoundDialog">Cancelar</v-btn>
                    <v-btn color="primary" text @click="addPaxToList">Agregar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
<v-dialog v-model="dialogDetails" max-width="700px" max-height="700px">
                 <v-card>
                  <v-card-title>Detalles del Pasajero {{ paxDetails.firstName }} {{ paxDetails.familyName }}</v-card-title>
                    <v-card-text>Pasaporte: {{ paxDetails.passportNumber }}</v-card-text>
                    <v-card-text>email: {{ paxDetails.email }}</v-card-text>
                     <v-card-text>Telefono: {{ paxDetails.phoneNumber }}</v-card-text>
                    <v-card-text>Reservas:</v-card-text>
                    <v-card v-for="reserva in paxBookings" v-bind:key="reserva.id" >
                        <v-card-text >Itinerario: {{ reserva.itinerary.code }}, Origen: {{ reserva.flight.sourceAirport.city }}({{ reserva.flight.sourceAirport.icaoCode }}), Destino: {{ reserva.flight.destinyAirport.city }}({{ reserva.flight.destinyAirport.icaoCode }})</v-card-text>
                        <!--<v-card-text>Creado Por: {{ pax.createdBy.username }}</v-card-text>
                        <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                        <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                        <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                   </v-card>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import gql from 'graphql-tag'
import { SELLER_LIST } from '../constants/graphql'
import { CREATE_PAX_MUTATION } from '../constants/graphql'
import { CREATE_BOOKING_MUTATION } from '../constants/graphql'
import { CREATE_ITINERARY_MUTATION } from '../constants/graphql'
import { UPDATE_ITINERARY_MUTATION } from '../constants/graphql'
import EditPax from '@/components/EditPax'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'FastItin',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
    data() {
        return {
            titulo:"AGREGAR",
                passport: '',
                first_name: '',
                last_name: '',
                paxs:[],
                selectedPax:[],
                rutaIda:0,
                rutaRegreso:0,
                rutaIdaFlights:[],
                rutaRegresoFlights:[],
                flights: [],
                itinerary: '',
                code:'',
                position:0,
                roundTrip:true,
                email: 'viajesadriitours@gmail.com',
                phone: '+573167119076',
                adult_type: 1,
                passport_type: 3,
                genero: '',
                nacionality: 'CUBA',
                movil: '+57318796475',
                idaFlight:'',
                returnFlight:'',
                rcount:'',
                icount:'',
                isEditing: true,
                headers: [
                  { text: 'Pasaporte', value: 'passportNumber' },
                  { text: 'Nombre', value: 'first_name' },
                  { text: 'Apellidos', value: 'last_name' },
                  {text: 'Action', value: 'actions'},
                ],
                paxId:25,
                showEdit: false,
                listPax:'',
                paxFound:'',
                paxToShow:'',
                paxToEdit:'',
                paxDetails:'',
                dialogDetails:false,
                btnStatus:true,
                update:0,
                icapacity:'',
                rcapacity:'',
                searching:false,
                fligthCount : 0,
                iterations : 0,
                contador : 0,
                idaBag:0,
                regresoBag:0,
                username: (JSON.parse(this.userStore.getUser)).username,
                equipaje:0,
                handEquipaje:0,
                conHotel:false,
                conHotelPost:false,
                Hotel: 1,
                lastIdaFlight:"",
                idaFlights:[],
                cityHoteles:[],
                departure_menu : false,
                validForm: true,
                bookingCount:0,
                paxBookings:[],
                vendedor:'',
                oldItinerary:'',
                nuevo: 'true',
                copy: 'false',
                allSellerList:'',
                itinMonthCount:0,
                tarifa :0.0,
                tarifaChild :0.0,
                tarifaInf :0.0,
                fee:0.0,
                idaFee:0.0,
                returnFee:0.0,
                idaTarifa :0.0,
                returnTarifa :0.0,
                taxFree:false,
                routeChange: false,
                seller: 1,

               }
            },
    apollo: {
    allEquipajes: gql`query {
    allEquipajes
      {
        id
        name
        quantity
        weight
        unit{
            id
            name
        }
        hand
      }
     }`,
    allRutasIda: gql`query {
      allRutasIda {
        id
        name
        flights
        dateDeparture
        ida
        activa
        agencyFee
        tarifaBase
        equipaje{
            id
            name
        }
      }
    }`,
    allHoteles: gql`query {
       allHoteles{
          id
          name
          ciudadAirport
          {
            id
            name
            city
            country
          }
          addressStr
          email
          phone
      }
    }`,
    allAdultTypes: gql`query {
      allAdultTypes {
        id
        name
        code
      }
    }`,
    allRutasRegreso: gql`query {
      allRutasRegreso {
        id
        name
        flights
        dateDeparture
        ida
        activa
        agencyFee
        tarifaBase
        equipaje{
            id
            name
        }
      }
    }`,
  },
  components: {
        EditPax,
  },
  watch: {
      conHotel: function(val){
         this.updateHoteles(val)
      }
    },
    computed: {
    isDisabled: function(){
        console.log(this.btnStatus)
        return this.btnStatus;
    },
    allEquipajesBodega(){return this.allEquipajes.filter(equipaje => equipaje.hand == 0)},
    allEquipajesMano(){return this.allEquipajes.filter(equipaje => equipaje.hand == 1)},
    },
    async created(){
     this.cityHoteles = this.allHoteles
     if(this.$route.params.id !=null){
            const itinerary = await this.$apollo.query({
                query: gql`query ($id: ID!) {
                    itineraryById(id:$id) {
                       id
                       remarks
                       roundtrip
                       rutaIda
                       rutaRegreso
                       tarifa
                       agencyFee
                       equipaje
                       {
                           id
                           name
                       }
                       hequipaje
                       {
                           id
                           name
                       }
                       hotel{
                          id
                          name
                       }

                 }
                }`,
                variables: {
                  id: this.$route.params.id,
                },fetchPolicy: "network-only"
            })
            this.oldItinerary = itinerary.data.itineraryById
            this.rutaIda = this.oldItinerary.rutaIda.toString()
            let rutaI = await this.getSelectedRuta(this.rutaIda)
            this.rutasIdaFlights = JSON.parse(rutaI.flights)
            this.rutaRegreso = this.oldItinerary.rutaRegreso.toString()
            let rutaR = await this.getSelectedRuta(this.rutaRegreso)
            this.rutasRegresoFlights = JSON.parse(rutaR.flights)
            this.equipaje = this.oldItinerary.equipaje.id
            this.handEquipaje = this.oldItinerary.hequipaje.id
            this.copy = true
            this.nuevo = false
            this.Hotel = this.oldItinerary.hotel.id
            this.roundTrip = this.oldItinerary.roundtrip
            this.tarifa = this.oldItinerary.tarifa
            this.agencyFee = this.oldItinerary.agencyFee
            this.routeSelected(rutaI,1)
            this.routeSelected(rutaR,0)
          }
     },
     async mounted(){
        this.loadSellerList()
        //this.loadItinCount()
     },
   beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Salir sin Salvar los Cambios?")) {
        return;
      }
    }
    next();
  },
  methods:{
        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
    textInput(){
        this.first_name = this.first_name.toUpperCase()
        this.searchPax()
        console.log(this.searching)
    },
   /* async loadItinCount(){
     await this.$apollo.query({
            query: ITIN_MONTH_COUNT,
            fetchPolicy: "network-only",
            }).then((data)=>{
               let sum = 0
               data.data.allItinerariesFromCurrentMonth.forEach(itin => sum = itin.paxCount + sum)
               this.itinMonthCount = sum
               console.log(this.itinMonthCount)
            })
   },*/
    async loadSellerList(){
     this.loading = true
     //var user = this.username
     await this.$apollo.query({
            query: SELLER_LIST,
            fetchPolicy: "network-only",
            }).then((data)=>{
            console.log(data.data.allUsers)
            this.allSellerList = data.data.allUsers.filter(user => user.isActive == true)
            this.loading = false
            })
   },
   async searchPax(){

        if(!this.searching){
         this.searching = true
         if(this.passport!=''){
         await this.$apollo.query({
            query: gql`query ($pp: String!) {
                paxByPp(pp:$pp){
                    id
                    passportNumber
                    firstName
                    familyName
                    createdBy{
                     id
                     username
                    }
                    paxBookings{
                      id
                      position
                      ida
                      itinerary{
                        id
                        isDeleted
                      }
                    }

                }
            }`,
            variables: {
              pp: this.passport,
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data)
               this.paxId = data.data.paxByPp.id
               this.first_name = data.data.paxByPp.firstName
               this.last_name = data.data.paxByPp.familyName
               this.bookingCount = data.data.paxByPp.paxBookings.filter(booking => booking.position == 0 && booking.ida == 1 && booking.itinerary.isDeleted == 0).length
               this.paxFound = true
           }).catch((error) => {
              // Error
              //alert("Pasajero no encontrado")
              this.btnStatus=false
              this.paxFound = false
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         }
     }
     },
     showDetailsDialog(item){
       this.paxToShow = item
       this.dialogDetails = true
       this.loadPaxDetails(item.id)
     },
     async loadPaxData(paxId){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            paxById(id:$id) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateModified
              dateValid
              isEditable
              title
              remarks
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }
              }
            }`,fetchPolicy: "network-only",
            variables: {
              id: paxId,
            }
        }).then((data)=>{
              this.paxToEdit = data.data.paxById
              console.log(data.data.paxById)
              this.showEdit = true
              this.update++
        })

      },
     async loadPaxDetails(paxId){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            paxById(id:$id) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateValid
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }
              paxBookings{
                      id
                      itinerary{
                        id
                        code
                        isDeleted
                      }
                      flight{
                        id
                        flightNumber
                        sourceAirport{
                           id
                           city
                           icaoCode
                        }
                        destinyAirport{
                           id
                           city
                           icaoCode
                        }

                      }
                      position
                      ida
                    }

            }
            }`,fetchPolicy: "network-only",
            variables: {
              id: paxId,
            }
        }).then((data)=>{
              this.paxDetails = data.data.paxById
              this.paxBookings = this.paxDetails.paxBookings.filter(booking => booking.position == 0 && booking.itinerary.isDeleted == 0)
              this.dialogDetails = true
              this.update++
        })

      },

      async loadHotelesByAirport(city){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            hotelesByAirport(id:$id) {
                }
            }`,fetchPolicy: "network-only",
            variables: {
              id: city,
            }
        }).then((data)=>{
              this.paxToEdit = data.data.paxById
              console.log(data.data.paxById)
              this.showEdit = true
              this.update++
        })

      },

     editPax(item)
     {
       this.paxId = item.id
       this.listPax = item
       this.loadPaxData(item.id)
     },

     hidePaxFoundDialog(){
      this.paxFound =  false
      this.searching = false
     },

     hideEdit(pp,family,first)
     {
       const paxidx = this.paxs.indexOf(this.listPax)
       this.paxs[paxidx].passportNumber = pp
       this.paxs[paxidx].first_name = first
       this.paxs[paxidx].last_name = family
       console.log(paxidx+'>>'+pp+'>>'+family+'>>'+first)
       this.showEdit = false
       this.paxId = 0
     },

     removePax (item) {
        const index = this.paxs.indexOf(item)
        if (index >= 0) this.paxs.splice(index, 1)
      },

     removeIdaFlight () {
         this.rutasIdaString =''
         this.rutaIda = null
         this.tarifa = this.tarifa-this.idaTarifa
         this.fee = this.fee - this.idaFee
      },

     removeReturnFlight () {
        this.rutasRegresoString = ''
        this.rutaRegreso = null
        this.tarifa = this.tarifa-this.returnTarifa
        this.fee = this.fee - this.returnFee

      },

     async getFlightCapacity(id,ida){
        console.log(id)
        await this.$apollo.query({
        query: gql`query ($id: ID!) {
            flightById(id:$id){
                id
                capacity
            }
        }`,
        variables: {
          id: id,
        },fetchPolicy: "network-only"
        }).then((data)=>{
            console.log(data.data.flightById.capacity)
            if(ida){
               this.icapacity = data.data.flightById.capacity
               if(this.icapacity <= (this.icount+this.paxs.filter(pax => pax.adult_type != 3).length)){
                alert("Ruta Esta Llena")
                this.removeIdaFlight ()
                }
            }
            else{
               this.rcapacity = data.data.flightById.capacity
               if(this.rcapacity <= (this.rcount+this.paxs.filter(pax => pax.adult_type != 3).length)){//se calcula la capacidad en funcion de la cantidad de pasajeros
                alert("Ruta Esta Llena")
                this.removeReturnFlight ()
                }
            }
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })

    },

    async getSelectedRuta(id){
        console.log(id)
        const ruta = await this.$apollo.query({
        query: gql`query ($id: ID!) {
            rutaById(id:$id){
                id
                flights
                ida
                tarifaBase
                agencyFee
                equipaje{
                    id
                    name
                }
                dateDeparture
            }
        }`,
        variables: {
          id: id,
        },fetchPolicy: "network-only"
        }).
        catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
            console.log(ruta.data.rutaById)
        return ruta.data.rutaById
    },
    async routeSelected(item,ida){
        if(!this.routeChange){
           this.routeChange = true
           this.tarifa = 0
           this.fee = 0
           }
        this.idaFlight = JSON.parse(item.flights)
         //if  (this.idaFlight.id != null)
        console.log(this.idaFlight[0].id)
        const flightId = this.idaFlight[0].id
        var flightDate = item.dateDeparture.substr(0,10)
        this.tarifa = this.tarifa + item.tarifaBase
        this.fee = this.fee + item.agencyFee
        console.log(item.id>>>flightId>>this.capacity)
        await this.$apollo.query({
        query: gql`query ($id: Int!) {
            countBookingsByFlight(flight:$id)
        }`,
        variables: {
          id: flightId,
        },fetchPolicy: "network-only",
        }).then((data)=>{
            if(ida){
                this.idaTarifa = item.tarifaBase
                this.idaFee = item.agencyFee
                this.icount = data.data.countBookingsByFlight
                console.log(flightDate)
                console.log(this.allRutasRegreso.length)
                this.allRutasRegreso = this.allRutasRegreso.filter(ruta => ruta.dateDeparture >= flightDate)
                console.log(this.allRutasRegreso.length)
            }
           else{
              this.returnTarifa = item.tarifaBase
              this.returnFee = item.agencyFee
              this.rcount = data.data.countBookingsByFlight
                console.log(flightDate)
              }
            })
            .catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
            this.getFlightCapacity(this.idaFlight[0].id,ida)
            this.updateHoteles(false)
    },

    async crearBtn(){
      await this.searchPax()
      if(!this.paxFound)
        if(this.passport != '' && this.first_name != '' && this.last_name != ''){
          this.createPax(this.passport,this.first_name,this.last_name,this.adult_type)
          this.btnStatus=true
          }
         else
           alert("Complete los datos que faltan")
       this.searching = false
    },

    async createPax(passport,first_name,last_name, adult_type, created_by=this.username, remarks = 'test',title='UNKNOW',email= 'viajesadriitours@gmail.com',
                phone= '+573167119076',
                passport_type= 1,
                nacionality= 59,
                movil= '+57318796475'){
        this.$apollo.mutate({
        mutation: CREATE_PAX_MUTATION,
        variables: {
            passport,
            first_name,
            last_name,
            created_by,
            remarks,
            title,
            adult_type,
            passport_type,
            nacionality,
            movil,
            phone,
            email,
        }
        }).then((data) => {
              if(data){
                  console.log(data)
                  this.selectedPax.push = data.data.createPassenger.passenger.id
                  this.paxId = data.data.createPassenger.passenger.id
                  console.log(this.paxId)
                  this.addPaxToList()
                  }
             }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })

    },

    hasCapacity(){
        return this.icapacity > this.icount
    },

    addPaxToList(){
              const pax = {
                id : this.paxId,
                passportNumber : this.passport,
                first_name : this.first_name,
                last_name : this.last_name,
                adult_type : this.adult_type,
              }
              this.searching = false
              this.paxFound = false
              this.paxs.push(pax)
              this.passport=''
              this.first_name=''
              this.last_name=''
              this.adult_type = 1
    },
    async  rutaToFlightsArray(){
          this.rutaIdaToFlightsArray()
          this.rutaReturnToFlightsArray()
       },
    async rutaIdaToFlightsArray(){
        console.log("Ruta>>>"+this.rutaIda)
        if(this.rutaIda){
            let ruta = await this.getSelectedRuta(this.rutaIda)
            this.rutasIdaFlights = JSON.parse(ruta.flights)
            console.log("fullBag: "+ruta.equipaje)
            this.idaBag = ruta.equipaje.id
            }
        else{
             alert("Seleccione una ruta de ida")
             this.validForm = false
           }
        },
    async rutaReturnToFlightsArray(){
        let regresoLength = 0
        if(this.roundTrip){
           if(this.rutaRegreso){
              let rutareg = await this.getSelectedRuta(this.rutaRegreso)
              this.rutasRegresoFlights = JSON.parse(rutareg.flights)
              console.log(this.rutasRegresoFlights)
              this.regresoBag = rutareg.equipaje.id
              regresoLength = this.rutasRegresoFlights.length
             }
           else{
               alert("Seleccione una ruta de regreso")
               this.validForm = false
             }
        }

        this.fligthCount = this.rutasIdaFlights.length + regresoLength
        console.log("Flights>>" +this.fligthCount)
    },

    async updateHoteles(val){
      this.rutaIdaToFlightsArray()
      if(this.rutasIdaFlights){

            console.log("Hoteles>"+this.rutasIdaFlights[this.rutasIdaFlights.length-1].id)
            await this.$apollo.query({
            query: gql`query ($flight: ID!) {
                flightById(id:$flight){
                      id
                      destinyAirport{
                        id
                      }
                  }
            }`,
            variables: {
              flight: this.rutasIdaFlights[this.rutasIdaFlights.length-1].id,
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data.data.flightById.destinyAirport.id)
               this.cityHoteles = this.allHoteles.filter(hotel => hotel.ciudadAirport.id == data.data.flightById.destinyAirport.id)
               if(val){
                    this.conHotelPost = val
                  }
           }).catch((error) => {
              // Error
              console.log(error)
            })
            }
    },
    updateItin(){
        const {
        itinerary,
        code,
        } = this.$data
        this.$apollo.mutate({
        mutation: UPDATE_ITINERARY_MUTATION,
        variables: {
            itinerary,
            code,
        }
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
    },

    async addBooking(itinerary, passenger, flight, position, bag,ida) {
        console.log("Bag: "+bag)
        this.$apollo.mutate({
        mutation: CREATE_BOOKING_MUTATION,
        variables: {
            itinerary,
            passenger,
            flight,
            ida,
            bag,
            position
            }
        }).then((data)=>{
            this.contador ++
            console.log(data)
            console.log("Counter>>" +this.contador+" ALl Iterations>>"+this.iterations)
            if(this.contador >= this.iterations)
               this.$router.push('/itineraries')
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })

    },

    async addAllBookings(pax){
        this.position=0
        this.rutasIdaFlights.forEach(
            flight => {
                this.addBooking(this.itinerary,pax,flight.id,this.position,this.idaBag,1)
                this.position++
                }
            )
        if(this.roundTrip){
            this.position=0
            this.rutasRegresoFlights.forEach(
                flight => {
                    this.addBooking(this.itinerary,pax,flight.id,this.position,this.regresoBag,0)
                    this.position++
                    }
                )
                }

        },

    async addAllPax(){
             const p = this.paxs.length
             const f = this.fligthCount
             this.iterations = p*f
             console.log("Iteraciones>>"+p+" "+f+">>" +this.iterations)
             this.paxs.forEach(pax =>{
                 this.addAllBookings(pax.id)

            })
            },


    async addItin(rutaIda = this.rutaIda, rutaRegreso = this.rutaRegreso, remarks="",user = this.username, equipaje = this.equipaje, handEquipaje = this.handEquipaje, hotel = this.Hotel, roundtrip = this.roundTrip){
        var paxCount = this.paxs.length
       // var adultCount = this.paxs.filter(p => p.adultType == "AD")
        //var childCount = this.paxs.filter(p => p.adultType == "CHD")
        //var infCount = this.paxs.filter(p => p.adultType == "INF")
        let seller = this.seller
        let tarifa = this.tarifa*paxCount//this.tarifa*adultCount+this.tarifaChild*childCount+this.tarifaInf*infCount
        let fee = this.fee*paxCount
        if(this.taxFree)
            fee=0.0
        console.log("user::"+user)
        return this.$apollo.mutate({
        mutation: CREATE_ITINERARY_MUTATION,
        variables: {
            seller,
            rutaIda,
            rutaRegreso,
            remarks,
            user,
            equipaje,
            handEquipaje,
            hotel,
            roundtrip,
            paxCount,
            tarifa,
            fee
        }
        }).then((data) => {
              if(data){
                  this.newItin = data.data.createItinerary.itinerary
                  this.itinerary = this.newItin.id
                  console.log(this.itinerary)
                  }
             }).then(() => {
             this.code = '23'+ (this.itinerary).toString().padStart(4,0)
             console.log("Este es el codigo >>>"+this.code+">>"+user)
             this.updateItin()
             })
             .catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         },

    async createItinerario(){
        if(this.equipaje == 0 && this.handEquipaje == 0){
        alert("Seleccione el Equipaje")}
        else{
        this.isEditing = false
        this.contador = 0
        if(this.rutaIda > 0 && this.paxs.length > 0){
            this.rutaToFlightsArray()
            .then(()=>{
              console.log(this.tarifa)
              if(this.validForm){
                this.addItin()
                .then(()=>{
                    this.addAllPax()
                    .then(()=>{
                        //this.$router.push('/itineraries')
                    })
                })

              }
              else
                {
                  alert("Complete el Formulario")
                }
            })

        }

      }
     }
  },
 }
</script>