<template>
  <div>
    <RutasList />
  </div>
</template>

<script>
import RutasList from '@/components/RutasList'

export default {
  name: 'AllFlights',
  components: {
    RutasList,
  },
}
</script>
