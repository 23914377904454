<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Equipajes</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addEquipaje"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>
    <CreateBag v-if = "createBag" @hideform = "hideCreateForm"/>
    <BagList v-if="showList" :equipajes="allEquipajes" key="equipajeKey"/>

  </div>
</template>

<script>
import gql from 'graphql-tag'
import {nextTick} from 'vue'
import BagList from '@/components/BagList'
import CreateBag from '@/components/CreateBag'

export default {
  name: 'AllBags',
  components: {
    BagList,
    CreateBag,
  },
  apollo: {
    allEquipajes: gql`{
          allEquipajes {
          id
          name
          weight
          quantity
          unit{
           id
           name
          }
          hand
    }
   }`,
  },
  data () {
    return {
      createBag : false,
      buttonTag:"Agregar Equipaje",
      equipajeKey : 0,
      showList: true,

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createBag = false
           this.buttonTag = "Agregar Equipaje"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addEquipaje(){
      await nextTick()
      this.equipajeKey = this.equipajeKey + 1
      this.createBag = !this.createBag
      this.showList = !this.showList
      if(this.createBag)
         this.buttonTag = "Mostrar Lista"
      else
         this.buttonTag = "Agregar Equipaje"
   },
   hideCreateForm(){
      this.createBag = !this.createBag
      this.showList = !this.showList
      this.buttonTag = "Agregar Equipaje"
   }
  },
}
</script>
