<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Terminal</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                       Terminal
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="terminalname"
                    @input="terminalname = terminalname.toUpperCase()"
                    v-validate="'required'"
                    name="ident"

                   >
                   </v-text-field>
                 </v-flex>
                  <v-flex xs12 sm6>
                    <label class="col-sm-12 control-label ">
                      Aeropuerto
                    </label>
                        <v-select class="ma-2" id="unit"
                        v-model="airport"
                         name="destinyeselect" :items="allAirports" item-value = "id" :item-text= "item => item.city +' ('+item.icaoCode+')'+', '+item.name" solo>
                        </v-select>
                </v-flex>
            </v-layout>


                <v-layout row>
               <v-btn color="primary" @click= "createTerminal" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   Salvar Terminal
               </v-btn>
                    <v-spacer></v-spacer>

            </v-layout>
      </v-container>
 </v-card>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_TERMINAL_MUTATION } from '../constants/graphql'

export default {
    name: 'CreateTerminal',
    apollo: {
    allAirports: gql`query {
      allAirports {
        id
        name
        city
        country
        iataCode
        icaoCode
      }
    }`,

  },
  data() {
        return {
            titulo:"AGREGAR",
                terminalname: '',
                airport: '',
               }
     },
  methods:{
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

    createTerminal(){
       this.createTerminalMutation(this.terminalname,this.airport)
    },

    async createTerminalMutation(name,aeropuerto){
        this.$apollo.mutate({
        mutation: CREATE_TERMINAL_MUTATION,
        variables: {
            name,
            aeropuerto,
          }
        }).then((data) => {
              if(data){
                  console.log(data)
                  /*this.selectedPax.push = data.data.createPassenger.passenger.id
                  this.paxId = data.data.createPassenger.passenger.id
                  console.log(this.paxId)
                  this.addBagToList()*/
                  this.$emit('hideform')
                  }
             }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
     }
    },



 }
</script>