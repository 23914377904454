<template>
  <div>
    <pItineraryViewList />
  </div>
</template>

<script>
//import gql from 'graphql-tag'
import pItineraryViewList from '@/components/pItineraryViewList'
export default {
  name: 'AllItineraries',
  components: {
    pItineraryViewList,
  },
   data () {
    return {
      confirmed: false,
      allItineraries:'',
      }
    },
  async created(){
  console.log("Created All")
    /*const Itineraries = await this.$apollo.query({
        query: gql`query {
          allItineraries {
          id
          code
  		  remarks
  		  roundtrip
  		  isDeleted
  		  EditionStatus{
  		    id
  		    name
  		  }
  		  createdBy{
  		    id
            username
            lastName
          }
          itinBookings{
            id
            pax{
              id
            }
            flight{
              id
              flightNumber
              airline{
                id
                name
                iata
              }
              sourceAirport{
                id
                name
                city
        		iataCode
              }
              dateDeparture
              destinyAirport{
                id
                name
                city
                iataCode
              }
              dateArrive
            }
            pnr
            ticket
            position
            ida
          }
      }
    }`,fetchPolicy: "network-only"
    })
    this.allItineraries = Itineraries.data.allItineraries*/

  },
}

</script>
