<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Itinerario</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      Denominacion
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="bagname"
                    @input="bagname = bagname.toUpperCase()"
                    v-validate="'required'"
                    name="ident"

                   >
                   </v-text-field>
                 </v-flex>
                  <v-flex xs12 sm6>
                    <label class="col-sm-12 control-label ">
                      Kg/Lb
                    </label>
                        <v-select class="ma-2" id="unit"
                        v-model="unit"
                        name="unitSelect" :items="allUnits" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      Cantidad
                   </label>
               <v-text-field
                    class="ma-2"
                    @input="textInput"
                    type = "number"
                    solo
                    id="cantidad"
                    v-model="cantidad"
                    v-validate="'required'"
                    name="cantidad">
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                <label class="col-sm-10 control-label ">
                  Peso
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="weight"
                    v-model="peso"
                    v-validate="'required'"
                    name= "weight">
                 </v-text-field>
              </v-flex>
            </v-layout>
                <!--Tabla con la lista de pasajeros creados-->

               <v-checkbox
                  v-model="hand"
                  label="Equipaje de Mano?"
                ></v-checkbox>


                <v-layout row>
               <v-btn color="primary" @click= "createEquipaje" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   Salvar Equipaje
               </v-btn>
                    <v-spacer></v-spacer>

            </v-layout>
      </v-container>
 </v-card>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_BAG_MUTATION } from '../constants/graphql'

export default {
    name: 'CreateBag',
    apollo: {
    allUnits: gql`query {
    allUnits
      {
        id
        name
      }
     }`,

  },
  data() {
        return {
            titulo:"AGREGAR",
                bagname: '',
                cantidad: '',
                unit: '',
                peso:'',
                hand:false,
               }
     },
  methods:{
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
    createEquipaje(){
       this.createBag(this.bagname,this.cantidad,this.peso, this.unit,this.hand)
    },
    async createBag(name,quantity,weight, unit, hand = 0){
        this.$apollo.mutate({
        mutation: CREATE_BAG_MUTATION,
        variables: {
            name,
            quantity,
            weight,
            unit,
            hand
          }
        }).then((data) => {
              if(data){
                  console.log(data)
                  /*this.selectedPax.push = data.data.createPassenger.passenger.id
                  this.paxId = data.data.createPassenger.passenger.id
                  console.log(this.paxId)
                  this.addBagToList()*/
                  }
             }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
     }
    },



 }
</script>