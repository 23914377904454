<template>
  <div>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="reservasList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :key = "tableKey"


        >
            <template v-slot:item.dateIn="{ item }">
                <td>{{ item.dateIn.substr(0,10)}}</td>
            </template>
            <template v-slot:item.dateOut="{ item }">
                <td>{{ item.dateOut.substr(0,10)}}</td>
            </template>
            <template v-slot:item.pax="{ item }">
                <td>{{ item.paxreservaSet[0].pax.passportNumber}}, {{item.paxreservaSet[0].pax.firstName}} {{item.paxreservaSet[0].pax.familyName}}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                     <div >
                       <v-icon v-if="item.editStatus != 'CANCELADO'"
                        medium
                        class="mr-2"
                        @click="exportar(item)"
                      >
                      mdi-home
                      </v-icon>
                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
         </v-card>
         <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Eliminacion</v-card-title>
                  <v-card-text>Desea Eliminar el Itinerario?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ALL_HOSTAL_BOOKING } from '../constants/graphql'
import { UPDATE_HOSTAL_BOOKING_MUTATION } from '../constants/graphql'
export default {
  name: 'ReservasList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Pax', value: 'pax' },
          { text: 'Hotel', value: 'hotel.name' },
          { text: 'Llegada', value: 'dateIn' },
          { text: 'Salida', value: 'dateOut' },
          { text: 'Actions', value: 'actions' },
        ],
        allReservas:[],
        flightInfo:'',
        dialogDelete: false,
        itemToDelete:'',
        tableKey:0,
        loading:false,
     }
  },
  components: {
  },
  props: {
    reservas: {
      type: Array,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    reservasList () {
      //return this.reservas

      return this.allReservas.filter(reservas => reservas.activa)
    },

  },

async mounted () {
     this.loadAllReservas()
    },
  methods: {
   showDeleteDialog(item){
            this.itemToDelete = item
            this.dialogDelete = true

      },
   deleteItem(){
        const index = this.reservasList.findIndex(itins => itins.id ==this.itemToDelete.id)
        console.log("updated_created>>"+index)
        if (index >= 0) this.reservasList.splice(index, 1)
        this.desactivarItem(this.itemToDelete.id,false)
   },
   async desactivarItem(reserva,activa){
        this.$apollo.mutate({
        mutation: UPDATE_HOSTAL_BOOKING_MUTATION,
        variables: {
            reserva,
            activa,
        }
        }).then(()=>{
           this.dialogDelete = false
           this.tableKey = this.tableKey+1
           //this.loadAllReservas()
        }
        )
   },
   async loadAllReservas(){
     this.loading = true
     await this.$apollo.query({
            query: ALL_HOSTAL_BOOKING,
            fetchPolicy: "network-only",
            }).then((data)=>{
            this.allReservas = data.data.allHotelBookings
            this.loading = false
            })
   },
   exportar(item) {
         //window.location.href="https://galvezapi.sgbcaribe.com/download_hostal_booking/"+item.id
        window.location.href="https://adriitours.alwaysdata.net/api/download_hostal_booking/"+item.id
         //window.location.href="http://localhost:8000/download_hostal_booking/"+item.id
        //this.downloadComponentInPDF(document.getElementById("toExport"))
        //this.saveAsPDF(document.getElementById("toExport"))
     },

   selectItem: function(item) {
      if (confirm('Seleccionar este Equipaje? ' + item.name)) {
            //this.$emit('paqueteselect', subscr.id)
            this.$router.push('/flight/'+ item.passportNumber)
       }
    },


        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },



    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
