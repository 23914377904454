<template>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Vendedores</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addPax"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>
    <CreateSeller v-if = "createSeller" @hideform = "hideCreateForm"/>
    <SellerList v-if = "showList" key="sellersKey"/>

  </div>
</template>

<script>

import {nextTick} from 'vue'
import SellerList from '@/components/SellerList'
import CreateSeller from '@/components/CreateSeller'


export default {
  name: 'AllSellerList',
  components: {
    SellerList,
    CreateSeller
  },

  data () {
    return {
      createSeller : false,
      showList : true,
      buttonTag:"Agregar",
      airlineKey : 0,
      loading: true,
      reservaKey:0,

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createReserva = false
           this.buttonTag = "Agregar Vendedor"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addPax(){
      await nextTick()
      this.reservaKey = this.reservaKey + 1
      this.createSeller = !this.createSeller
      this.showList = !this.showList
      if(this.createSeller)
         this.buttonTag = "Mostrar Lista"
      else
         this.buttonTag = "Agregar Vendedor"
   },

   hideCreateForm(){
      console.log("Event Received")
      this.reservaKey = this.reservaKey + 1
      this.createSeller= !this.createSeller
      this.showList = !this.showList
      this.buttonTag = "Agregar Vendedor"
   }
  },
}
</script>
