<template>
  <div>

      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="terminalList"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :key = "tableKey"


        >
            <template v-slot:item.dateIn="{ item }">
                <td>{{ item.dateIn.substr(0,10)}}</td>
            </template>
            <template v-slot:item.dateOut="{ item }">
                <td>{{ item.dateOut.substr(0,10)}}</td>
            </template>
            <template v-slot:item.pax="{ item }">
                <td>{{ item.paxTerminalet[0].pax.passportNumber}}, {{item.paxTerminalet[0].pax.firstName}} {{item.paxTerminalet[0].pax.familyName}}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                     <div >
                      <v-icon
                        medium
                        color ="red"
                        @click="showDeleteDialog(item)"
                      >
                      mdi-delete
                      </v-icon>
                  </div>
          </template>
   </v-data-table>
         </v-card>
         <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title>Alerta de Eliminacion</v-card-title>
                  <v-card-text>Desea Eliminar la terminal?</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDelete = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deleteItem()">Eliminar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ALL_TERMINALS_QUERY } from '../constants/graphql'
export default {
  name: 'TerminalList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Name', value: 'name' },
          { text: 'Aeropuerto', value: 'aeropuerto.name' },
          { text: 'Actions', value: 'actions' },
        ],
        allTerminal:[],
        terminalInfo:'',
        dialogDelete: false,
        itemToDelete:'',
        tableKey:0,
     }
  },
  components: {
  },
  props: {
    Terminal: {
      type: Array,
    },
    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    terminalList () {
      return this.allTerminal

      //return this.allTerminal.filter(Terminal => Terminal.activa)
    },

  },

async mounted () {
     this.loadAllTerminal()
    },
  methods: {
   showDeleteDialog(item){
            this.itemToDelete = item
            this.dialogDelete = true

      },
   deleteItem(){
        const index = this.TerminalList.findIndex(itins => itins.id ==this.itemToDelete.id)
        console.log("updated_created>>"+index)
        if (index >= 0) this.TerminalList.splice(index, 1)
        this.desactivarItem(this.itemToDelete.id,false)
   },
  /* async desactivarItem(reserva,activa){
        this.$apollo.mutate({
        mutation: UPDATE_HOSTAL_BOOKING_MUTATION,
        variables: {
            reserva,
            activa,
        }
        }).then(()=>{
           this.dialogDelete = false
           this.tableKey = this.tableKey+1
           //this.loadAllTerminal()
        }
        )
   },*/
   async loadAllTerminal(){
     this.loading = true
     await this.$apollo.query({
            query: ALL_TERMINALS_QUERY,
            fetchPolicy: "network-only",
            }).then((data)=>{
            this.allTerminal = data.data.allTerminals
            this.loading = false
            })
   },
   selectItem: function(item) {
      if (confirm('Seleccionar este Equipaje? ' + item.name)) {
            //this.$emit('paqueteselect', subscr.id)
            this.$router.push('/flight/'+ item.passportNumber)
       }
    },


        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },



    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
