<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Agregar Pasajero a Lista de Espera</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.id')}}
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="passport"
                    @input="passport = passport.toUpperCase()"
                    v-validate="'required'"
                    name="ident"
                    v-bind:placeholder="$t('lang.persons.id')"
                    append-outer-icon="mdi-account-search"
                    @click:append-outer="searchPax"
                   >
                   </v-text-field>
                 </v-flex>
                <!--     <v-flex xs12 sm6>
                    <label class="col-sm-12 control-label ">
                      {{$t('lang.persons.adultotype')}}
                    </label>
                        <v-select class="ma-2" id="adult"
                        v-model="adult_type"
                        name="adultselect" :items="allAdultTypes" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>
                -->
               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.first_name')}}
                   </label>
               <v-text-field
                    class="ma-2"
                    @input="textInput"
                    solo
                    id="first_name"
                    v-model="first_name"
                    v-validate="'required'"
                    name="first_name">
                   @click="searchPax"
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.last_name')}}
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="last_name"
                    v-model="last_name"
                    @input="last_name = last_name.toUpperCase()"
                    v-validate="'required'"
                    name= "last_name">
                 </v-text-field>
            </v-flex>
            -->
              <v-flex xs11 sm6 class="pt-16 ">
               <v-btn color="primary" @click= "$router.push('/newpax')" class="col-sm-11 mx-3">
                   <v-icon medium>
                    mdi-save
                   </v-icon>
                   {{$t('lang.persons.addbutton')}}
               </v-btn>
            </v-flex>
            </v-layout>
                <!--Tabla con la lista de pasajeros creados-->
            <v-layout row>
               <v-col xs12>
                <v-data-table
                 :headers="headers"
                 :items="paxs"
                 :items-per-page="10"
                 class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                             <div class="text-truncate">
                                <v-icon
                                medium
                                @click="editPax(item)"
                              >
                              mdi-pencil
                              </v-icon>
                              <v-icon
                                medium
                                class="mr-2"
                                @click="showDetailsDialog(item)"
                              >
                              mdi-details
                              </v-icon>
                              <v-icon
                                medium
                                color ="red"
                                @click="removePax(item)"
                              >
                              mdi-delete
                              </v-icon>
                          </div>
                  </template>
                </v-data-table>
               </v-col>
            </v-layout>

                <!--lista de Hoteles-->
                <v-layout row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="destino"
                      :items="allAirports"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label="Seleccione el Destino"
                      item-text="name"
                      item-value="id"
                      :menu-props="{closeOnContentClick:true}"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          <v-avatar left>
                            <v-img :src="data.item.avatar"></v-img>
                          </v-avatar>
                           {{ data.item.city }}, {{ data.item.country}}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content @click="destinySelected(data.item,1)">
                            <v-list-item-title >  {{ data.item.city }}, {{ data.item.country}}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                </v-layout>

                       <!--Departure date and time-->


                <v-layout row>
               <v-btn color="primary" @click= "createBooking" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   Agregar a la lista de espera
               </v-btn>
                    <v-spacer></v-spacer>
               <v-btn color="red" @click= "gohome" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-close
                   </v-icon>
                   {{$t('lang.itinerary.sclose')}}
               </v-btn>
            </v-layout>
      </v-container>
 </v-card>
      </v-flex>
    </v-layout>
<v-dialog v-model="showEdit">
    <EditPax :pax="paxToEdit" @hide = "hideEdit" v-bind:key="update"/>
</v-dialog>
<v-dialog v-model="paxFound" max-width="500px">
                <v-card>
                  <v-card-title>Pasajero Encontrado</v-card-title>
                  <v-card-text>Pasaporte: {{passport}}</v-card-text>
                  <v-card-text>Nombre: {{first_name}} {{last_name}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="hidePaxFoundDialog">Cancelar</v-btn>
                    <v-btn color="primary" text @click="addPaxToList">Agregar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
<v-dialog v-model="dialogDetails" max-width="500px" max-height="700px" v-if="paxToShow">
                <v-card>
                  <v-card-title>Detalles del Pasajero {{ paxToShow.first_name }} {{ paxToShow.last_name }}</v-card-title>
                    <v-card-text>Pasaporte: {{ paxToShow.passportNumber }}</v-card-text>
                     <v-card-text>Telefono: {{ paxToShow.phoneNumber }}</v-card-text>
                    <v-card-text>email: {{ paxToShow.email }}</v-card-text>

                    <!--<v-card-text>Fecha de Expedicion: {{ displayableDate(pax.dateExpired) }}</v-card-text>
                    <v-card-text>Fecha de nacimiento: {{ displayableDate(pax.dateBorn) }}</v-card-text>
                    <v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>
                    <v-card-text>Creado Por: {{ pax.createdBy.username }}</v-card-text>
                    <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                    <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                    <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import gql from 'graphql-tag'
import { ADD_TO_WAITING_LIST_MUTATION } from '../constants/graphql'

import EditPax from '@/components/EditPax'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'AddToList',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
    data() {
        return {
            titulo:"AGREGAR",
                passport: '',
                first_name: '',
                last_name: '',
                paxs:[],
                selectedPax:[],
                reserva: '',
                newBooking:'',
                email: 'viajesadriitours@gmail.com',
                phone: '+573167119076',
                adult_type: 1,
                passport_type: 3,
                genero: '',
                nacionality: 'CUBA',
                movil: '+57318796475',
                headers: [
                  { text: 'Pasaporte', value: 'passportNumber' },
                  { text: 'Nombre', value: 'first_name' },
                  { text: 'Apellidos', value: 'last_name' },
                  {text: 'Action', value: 'actions'},
                ],
                paxId:25,
                showEdit: false,
                listPax:'',
                paxFound:'',
                paxToShow:'',
                paxToEdit:'',
                dialogDetails:false,
                btnStatus:true,
                update:0,
                searching:false,
                username: (JSON.parse(this.userStore.getUser)).id,
                equipaje:0,
                handEquipaje:0,
                conHotel:false,
                conHotelPost:false,
                Hotel: 1,
                lastIdaFlight:"",
                idaFlights:[],
                destinos:[],
                destino:'',
                arrive_menu: false,
                departure_date: null,
                arrive_date: null,
                departure_menu: false,
                menu: false,
                modal: false,
                menu2: false,
                modal2: false,
                dateDeparture:null,
               }
            },
    apollo: {

    allAirports: gql`query {
       allAirports{
            id
            name
            city
            country

      }
    }`,

  },
  components: {
        EditPax,
  },
  watch: {
      born_menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      issue_menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
      conHotel: function(val){
         this.updateHoteles(val)
      },
      arrive_menu (val) {
        val && setTimeout(() => (this.$refs.arrivepicker.activePicker = 'YEAR'))
      },
      departure_menu (val) {
        val && setTimeout(() => (this.$refs.departurepicker.activePicker = 'YEAR'))
      },

    },
    computed: {
    isDisabled: function(){
        console.log(this.btnStatus)
        return this.btnStatus;
    }
    },
    async created(){
     this.destinos = this.allAirports
     },
  methods:{
      arrive_date_save (arrive_date) {
      if(this.arrive_date == this.departure_date)
        {
          this.minTimeArrive = this.timeDeparture
        }
        this.$refs.arrive_menu.save(arrive_date)
      },
      departure_date_save (departure_date) {
        this.$refs.departure_menu.save(departure_date)
      },
        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

    textInput(){
        this.first_name = this.first_name.toUpperCase()
        this.searchPax()
        console.log(this.searching)
    },
     async searchPax(){

        if(!this.searching){
         this.searching = true
         if(this.passport!=''){
         await this.$apollo.query({
            query: gql`query ($pp: String!) {
                paxByPp(pp:$pp){
                    id
                    passportNumber
                    firstName
                    familyName
                }
            }`,
            variables: {
              pp: this.passport,
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data)
               this.paxId = data.data.paxByPp.id
               this.first_name = data.data.paxByPp.firstName
               this.last_name = data.data.paxByPp.familyName
               this.paxFound = true
           }).catch((error) => {
              // Error
              //alert("Pasajero no encontrado")
              this.btnStatus=false
              this.paxFound = false
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         }
     }
     },
     showDetailsDialog(item){
       this.paxToShow = item
       this.dialogDetails = true
       this.loadPaxDetails(item.passportNumber)
     },
     async loadPaxDetails(paxId){
        await this.$apollo.query({
          query: gql`query ($pp: String!) {
            paxByPp(pp:$pp) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateModified
              dateValid
              isEditable
              title
              remarks
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }

            }
            }`,fetchPolicy: "network-only",
            variables: {
              pp: paxId,
            }
        }).then((data)=>{
              this.paxToShow = data.data.paxByPp
              console.log(data.data.paxById)
              this.update++
        })

      },
     async loadPaxData(paxId){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            paxById(id:$id) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateModified
              dateValid
              isEditable
              title
              remarks
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }

            }
            }`,fetchPolicy: "network-only",
            variables: {
              id: paxId,
            }
        }).then((data)=>{
              this.paxToEdit = data.data.paxById
              console.log(data.data.paxById)
              this.showEdit = true
              this.update++
        })

      },

     editPax(item)
     {
       this.paxId = item.id
       this.listPax = item
       this.loadPaxData(item.id)
     },

     hidePaxFoundDialog(){
      this.paxFound =  false
      this.searching = false
     },

     hideEdit(pp,family,first)
     {
       const paxidx = this.paxs.indexOf(this.listPax)
       this.paxs[paxidx].passportNumber = pp
       this.paxs[paxidx].first_name = first
       this.paxs[paxidx].last_name = family
       console.log(paxidx+'>>'+pp+'>>'+family+'>>'+first)
       this.showEdit = false
       this.paxId = 0
     },
     destinySelected(){
         console.log("Destiny selected")
     },
     removePax (item) {
        const index = this.paxs.indexOf(item)
        if (index >= 0) this.paxs.splice(index, 1)
      },

    async crearBtn(){

    },

    addPaxToList(){
              const pax = {
                id : this.paxId,
                passportNumber : this.passport,
                first_name : this.first_name,
                last_name : this.last_name,
                adult_type : this.adult_type,
              }
              this.searching = false
              this.paxFound = false
              this.paxs.push(pax)
              this.passport=''
              this.first_name=''
              this.last_name=''
              this.adult_type = 1
    },


   async addPaxToWaitingList(destino, pax) {
        var user = this.username
        this.$apollo.mutate({
        mutation: ADD_TO_WAITING_LIST_MUTATION,
        variables: {
            destino,
            pax,
            user
            }
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              alert("Error Agregando el Pasajero. Revise si ya esta en la lista para el destino solicitado:" +error)
              // We restore the initial user input
            })

    },
   async allPaxToList(){
        this.paxs.forEach(pax =>{
                 this.addPaxToWaitingList(this.destino,pax.id)

            })
     },

   async createBooking(){
            await this.allPaxToList()
            .then(()=>{
               this.$emit('hideform')
            })

            },
  }
 }
</script>