import { render, staticRenderFns } from "./HostalBooking.vue?vue&type=template&id=279cc871&scoped=true&"
import script from "./HostalBooking.vue?vue&type=script&lang=js&"
export * from "./HostalBooking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279cc871",
  null
  
)

export default component.exports