<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Reserva de Hostal</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm6>
                   <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.id')}}
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="passport"
                    @input="passport = passport.toUpperCase()"
                    v-validate="'required'"
                    name="ident"
                    v-bind:placeholder="$t('lang.persons.id')"
                    append-outer-icon="mdi-account-search"
                    @click:append-outer="searchPax"
                   >
                   </v-text-field>
                 </v-flex>
                     <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      {{$t('lang.persons.adultotype')}}
                    </label>
                        <v-select class="ma-2" id="adult"
                        v-model="adult_type"
                        name="adultselect" :items="allAdultTypes" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>

               <!--         <v-list >
                            <v-list-item  v-for="item in filteredUserFeed" v-bind:key="item.id" link :to="item.first_name == '#' ? '' : item.path">
                             <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.ident }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title @click = "selectusuarios(item)">{{ item.first_name }} {{ item.last_name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        -->
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      {{$t('lang.persons.first_name')}}
                   </label>
               <v-text-field
                    class="ma-2"
                    @input="textInput"
                    solo
                    id="first_name"
                    v-model="first_name"
                    v-validate="'required'"
                    name="first_name">
                   @click="searchPax"
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                <label class="col-sm-10 control-label ">
                  {{$t('lang.persons.last_name')}}
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="last_name"
                    v-model="last_name"
                    @input="last_name = last_name.toUpperCase()"
                    v-validate="'required'"
                    name= "last_name">
                 </v-text-field>
            </v-flex>

              <v-flex xs12 sm4 class="pt-16 ">
               <!--<v-btn color="primary" @click= "$router.push('/newpax')" class="col-sm-11 mx-3">-->
               <v-btn color="primary" @click= "crearBtn" class="col-sm-11 mx-3">
                   <v-icon medium>
                    mdi-save
                   </v-icon>
                   {{$t('lang.persons.addbutton')}}
               </v-btn>
            </v-flex>
            </v-layout>
                <!--Tabla con la lista de pasajeros creados-->
            <v-layout row>
               <v-col xs12>
                <v-data-table
                 :headers="headers"
                 :items="paxs"
                 :items-per-page="10"
                 class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                             <div class="text-truncate">
                                <v-icon
                                medium
                                @click="editPax(item)"
                              >
                              mdi-pencil
                              </v-icon>
                              <v-icon
                                medium
                                class="mr-2"
                                @click="showDetailsDialog(item)"
                              >
                              mdi-details
                              </v-icon>
                              <v-icon
                                medium
                                color ="red"
                                @click="removePax(item)"
                              >
                              mdi-delete
                              </v-icon>
                          </div>
                  </template>
                </v-data-table>
               </v-col>
            </v-layout>

                <!--lista de Hoteles-->
                <v-layout row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="Hotel"
                      :items="allHoteles"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label="Seleccione el Hotel"
                      item-text="name"
                      item-value="id"
                      :menu-props="{closeOnContentClick:true}"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeHotel(data.item)"
                        >
                          <v-avatar left>
                            <v-img :src="data.item.avatar"></v-img>
                          </v-avatar>
                          {{ data.item.name}},  {{ data.item.ciudadAirport.city }}, {{ data.item.ciudadAirport.country}}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content @click="hotelSelected(data.item,1)">
                            <v-list-item-title >{{ data.item.name}},  {{ data.item.ciudadAirport.city }}, {{ data.item.ciudadAirport.country}}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                </v-layout>

                       <!--Departure date and time-->
                 <v-layout row>
                      <!--Departure date picker-->
                  <v-flex xs12 sm6>
                   <v-menu
                    ref="departure_menu"
                    v-model="departure_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="departure_date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        label="Fecha de Entrada"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="departurepicker"
                      no-title
                      v-model="departure_date"
                      :min="new Date().toISOString().substr(0, 10)"
                      @change="departure_date_save"
                    ></v-date-picker>
                  </v-menu>
                 </v-flex>
                  <v-spacer></v-spacer>
                      <!--Departure time picker-->
                  <!--Arrive date picker-->
                  <v-flex xs12 sm6>
                    <v-menu
                        ref="arrive_menu"
                        v-model="arrive_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="arrive_date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            label="Fecha de Salida"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="arrivepicker"
                          no-title
                          v-model="arrive_date"
                          :min="departure_date"
                          @change="arrive_date_save"
                        ></v-date-picker>
                      </v-menu>
                     </v-flex>
                  <!--Arrive time picker-->
                 </v-layout>

                <v-layout row>
               <v-btn color="primary" @click= "createBooking" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   Reservar Hostal
               </v-btn>
                    <v-spacer></v-spacer>
               <v-btn color="red" @click= "gohome" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-close
                   </v-icon>
                   {{$t('lang.itinerary.sclose')}}
               </v-btn>
            </v-layout>
      </v-container>
 </v-card>
      </v-flex>
    </v-layout>
<v-dialog v-model="showEdit">
    <EditPax :pax="paxToEdit" @hide = "hideEdit" v-bind:key="update"/>
</v-dialog>
<v-dialog v-model="paxFound" max-width="500px">
                <v-card>
                  <v-card-title>Pasajero Encontrado</v-card-title>
                  <v-card-text>Pasaporte: {{passport}}</v-card-text>
                  <v-card-text>Nombre: {{first_name}} {{last_name}}</v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="hidePaxFoundDialog">Cancelar</v-btn>
                    <v-btn color="primary" text @click="addPaxToList">Agregar</v-btn>
                  </v-card-actions>
                </v-card>
    </v-dialog>
<v-dialog v-model="dialogDetails" max-width="500px" max-height="700px" v-if="paxToShow">
                <v-card>
                  <v-card-title>Detalles del Pasajero {{ paxToShow.first_name }} {{ paxToShow.last_name }}</v-card-title>
                    <v-card-text>Pasaporte: {{ paxToShow.passportNumber }}</v-card-text>
                    <!--<v-card-text>Fecha de Expedicion: {{ displayableDate(pax.dateExpired) }}</v-card-text>
                    <v-card-text>Fecha de nacimiento: {{ displayableDate(pax.dateBorn) }}</v-card-text>
                    <v-card-text>Creado: {{ displayableDate(pax.dateCreated) }}</v-card-text>
                    <v-card-text>Creado Por: {{ pax.createdBy.username }}</v-card-text>
                    <v-card-text>Modificado: {{ displayableDate(pax.dateModified) }}</v-card-text>
                    <v-card-text>Estado: {{ paxEditionEstatus(pax) }}</v-card-text>
                    <v-card-text>Comentario: {{ pax.remarks }}</v-card-text>-->
                  <v-card-actions>
                    <v-btn color="primary" text @click="dialogDetails = false">Aceptar</v-btn>

                  </v-card-actions>
                </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_PAX_MUTATION } from '../constants/graphql'
import { CREATE_HOSTAL_BOOKING_MUTATION } from '../constants/graphql'
import { CREATE_HOSTAL_PAX_BOOKING_MUTATION } from '../constants/graphql'

import EditPax from '@/components/EditPax'
import {computed} from "vue";
import { useUserStore } from "@/stores/user";
export default {
    name: 'CreateHostalBooking',
          setup() {
            const userStore = useUserStore();
            const info  = computed(() => userStore.getUser);
            return { userStore,info };
          },
    data() {
        return {
            titulo:"AGREGAR",
                passport: '',
                first_name: '',
                last_name: '',
                paxs:[],
                selectedPax:[],
                reserva: '',
                newBooking:'',
                email: 'viajesadriitours@gmail.com',
                phone: '+573167119076',
                adult_type: 1,
                passport_type: 3,
                genero: '',
                nacionality: 'CUBA',
                movil: '+57318796475',
                headers: [
                  { text: 'Pasaporte', value: 'passportNumber' },
                  { text: 'Nombre', value: 'first_name' },
                  { text: 'Apellidos', value: 'last_name' },
                  {text: 'Action', value: 'actions'},
                ],
                paxId:25,
                showEdit: false,
                listPax:'',
                paxFound:'',
                paxToShow:'',
                paxToEdit:'',
                dialogDetails:false,
                btnStatus:true,
                update:0,
                searching:false,
                username: (JSON.parse(this.userStore.getUser)).username,
                equipaje:0,
                handEquipaje:0,
                conHotel:false,
                conHotelPost:false,
                Hotel: 1,
                lastIdaFlight:"",
                idaFlights:[],
                cityHoteles:[],
                arrive_menu: false,
                departure_date: null,
                arrive_date: null,
                departure_menu: false,
                menu: false,
                modal: false,
                menu2: false,
                modal2: false,
                dateDeparture:null,
               }
            },
    apollo: {
    allEquipajes: gql`query {
    allEquipajes
      {
        id
        name
        quantity
        weight
        unit{
            id
            name
        }
        hand
      }
     }`,
    allRutasIda: gql`query {
      allRutasIda {
        id
        name
        flights
        dateDeparture
        ida
        activa
        equipaje{
            id
            name
        }
      }
    }`,
    allHoteles: gql`query {
       allHoteles{
          id
          name
          ciudadAirport
          {
            id
            name
            city
            country
          }
          addressStr
          email
          phone
      }
    }`,
    allAdultTypes: gql`query {
      allAdultTypes {
        id
        name
        code
      }
    }`,
    allRutasRegreso: gql`query {
      allRutasRegreso {
        id
        name
        flights
        dateDeparture
        ida
        activa
        equipaje{
            id
            name
        }
      }
    }`,
  },
  components: {
        EditPax,
  },
  watch: {

      conHotel: function(val){
         this.updateHoteles(val)
      },


    },
    computed: {
    isDisabled: function(){
        console.log(this.btnStatus)
        return this.btnStatus;
    },
    allEquipajesBodega(){return this.allEquipajes.filter(equipaje => equipaje.hand == 0)},
    allEquipajesMano(){return this.allEquipajes.filter(equipaje => equipaje.hand == 1)},
    },
    async created(){
     this.cityHoteles = this.allHoteles
     },
  methods:{
      arrive_date_save (arrive_date) {
      if(this.arrive_date == this.departure_date)
        {
          this.minTimeArrive = this.timeDeparture
        }
        this.$refs.arrive_menu.save(arrive_date)
      },
      departure_date_save (departure_date) {
        this.$refs.departure_menu.save(departure_date)
      },
        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
    textInput(){
        this.first_name = this.first_name.toUpperCase()
        this.searchPax()
        console.log(this.searching)
    },
     async searchPax(){

        if(!this.searching){
         this.searching = true
         if(this.passport!=''){
         await this.$apollo.query({
            query: gql`query ($pp: String!) {
                paxByPp(pp:$pp){
                    id
                    passportNumber
                    firstName
                    familyName
                }
            }`,
            variables: {
              pp: this.passport,
            },fetchPolicy: "network-only"
            }).then((data)=> {
               console.log(data)
               this.paxId = data.data.paxByPp.id
               this.first_name = data.data.paxByPp.firstName
               this.last_name = data.data.paxByPp.familyName
               this.paxFound = true
           }).catch((error) => {
              // Error
              //alert("Pasajero no encontrado")
              this.btnStatus=false
              this.paxFound = false
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         }
     }
     },
     showDetailsDialog(item){
       this.paxToShow = item
       this.dialogDetails = true
     },
     async loadPaxData(paxId){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            paxById(id:$id) {
              id
              passportNumber
              familyName
              firstName
              dateBorn
              dateExpired
              dateCreated
              dateModified
              dateValid
              isEditable
              title
              remarks
              email
              adultType{
                id
                name
                code
              }
              civil{
                id
                name
                code
              }
              passportType{
                id
                name
                code
              }
              genero{
                id
                name
                code
              }
              nacionality{
                id
                nationality
              }
              issueCountry{
                id
                enShortName
              }
              housePhone
              phoneNumber
              createdBy {
                id
                firstName
                username
                lastName
              }

            }
            }`,fetchPolicy: "network-only",
            variables: {
              id: paxId,
            }
        }).then((data)=>{
              this.paxToEdit = data.data.paxById
              console.log(data.data.paxById)
              this.showEdit = true
              this.update++
        })

      },

      async loadHotelesByAirport(city){
        await this.$apollo.query({
          query: gql`query ($id: ID!) {
            hotelesByAirport(id:$id) {
                }
            }`,fetchPolicy: "network-only",
            variables: {
              id: city,
            }
        }).then((data)=>{
              this.paxToEdit = data.data.paxById
              console.log(data.data.paxById)
              this.showEdit = true
              this.update++
        })

      },

     editPax(item)
     {
       this.paxId = item.id
       this.listPax = item
       this.loadPaxData(item.id)
     },

     hidePaxFoundDialog(){
      this.paxFound =  false
      this.searching = false
     },

     hideEdit(pp,family,first)
     {
       const paxidx = this.paxs.indexOf(this.listPax)
       this.paxs[paxidx].passportNumber = pp
       this.paxs[paxidx].first_name = first
       this.paxs[paxidx].last_name = family
       console.log(paxidx+'>>'+pp+'>>'+family+'>>'+first)
       this.showEdit = false
       this.paxId = 0
     },
     hotelSelected(){
         console.log("Hotel Selected")
     },
     removePax (item) {
        const index = this.paxs.indexOf(item)
        if (index >= 0) this.paxs.splice(index, 1)
      },

     removeIdaFlight () {
         this.rutasIdaString =''
      },


    async crearBtn(){
      await this.searchPax()
      if(!this.paxFound)
        console.log("CReatedBy:" + this.created_by)
        if(this.passport != '' && this.first_name != '' && this.last_name != ''){
          this.createPax(this.passport,this.first_name,this.last_name,this.adult_type, this.username)
          this.btnStatus=true
          }
         else
           alert("Complete los datos que faltan")
       this.searching = false
    },

    async createPax(passport,first_name,last_name, adult_type, created_by, remarks = 'test',title='UNKNOW',email= 'viajesadriitours@gmail.com',
                phone= '+573167119076',
                passport_type= 1,
                nacionality= 59,
                movil= '+57318796475'){
        this.$apollo.mutate({
        mutation: CREATE_PAX_MUTATION,
        variables: {
            passport,
            first_name,
            last_name,
            created_by,
            remarks,
            title,
            adult_type,
            passport_type,
            nacionality,
            movil,
            phone,
            email,
        }
        }).then((data) => {
              if(data){
                  console.log(data)
                  this.selectedPax.push = data.data.createPassenger.passenger.id
                  this.paxId = data.data.createPassenger.passenger.id
                  console.log(this.paxId)
                  this.addPaxToList()
                  }
             }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })

    },

    addPaxToList(){
              const pax = {
                id : this.paxId,
                passportNumber : this.passport,
                first_name : this.first_name,
                last_name : this.last_name,
                adult_type : this.adult_type,
              }
              this.searching = false
              this.paxFound = false
              this.paxs.push(pax)
              this.passport=''
              this.first_name=''
              this.last_name=''
              this.adult_type = 1
    },


    async addPaxBooking(booking, pax) {
        this.$apollo.mutate({
        mutation: CREATE_HOSTAL_PAX_BOOKING_MUTATION,
        variables: {
            booking,
            pax,
            }
        }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })

    },

    async addAllPax(){
             this.paxs.forEach(pax =>{
                 this.addPaxBooking(this.reserva,pax.id)

            })
            },


    async addHotelBooking(hotel, dateArrive, dateDeparture){
        //console.log("user::"+user)
        return this.$apollo.mutate({
        mutation: CREATE_HOSTAL_BOOKING_MUTATION,
        variables: {
            hotel,
            dateDeparture,
            dateArrive
        }
        }).then((data) => {
              if(data){
                  console.log(data)
                  this.newBooking = data.data.createHostalBooking.hostalBooking
                  this.reserva = this.newBooking.id
                  console.log(this.itinerary)
                  }
             })
             .catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              // We restore the initial user input
            })
         },

    async createBooking(){
            await this.addHotelBooking(this.Hotel, this.departure_date, this.arrive_date,)
            await this.addAllPax().then(()=>{
                console.log("Event Emited")
                this.$emit('hideform')
            })
     }
  },
 }
</script>