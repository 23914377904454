<template xmlns:v-bind="http://www.w3.org/1999/xhtml">

    <div>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Crear Itinerario</v-toolbar-title>
          </v-toolbar>
            <v-container>
            <v-layout row wrap>
                  <v-flex xs12 sm4>
                   <label class="col-sm-10 control-label ">
                      Nombre de Hotel
                   </label>
                   <v-text-field
                    class="ma-2"
                    solo
                    autocomplete="off"
                    id="ident"
                    v-model="hotelName"
                    @input="hotelName = hotelName.toUpperCase()"
                    v-validate="'required'"
                    name="ident"

                   >
                   </v-text-field>
                 </v-flex>
                  <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      Ciudad/Aeropuerto
                    </label>
                        <v-select class="ma-2" id="unit"
                        v-model="airport"
                        name="airportSelect" :items="allAirports" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>
                   <v-flex xs12 sm4>
                    <label class="col-sm-12 control-label ">
                      Categoria
                    </label>
                        <v-select class="ma-2" id="unit"
                        v-model="category"
                        name="categorySelect" :items="allCategories" item-value = "id" item-text="name" solo>
                        </v-select>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      Telefono
                   </label>
               <v-text-field
                    class="ma-2"
                    solo
                    id="phone"
                    v-model="phone"
                    v-validate="'required'"
                    name="phone">
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                    <label class="col-sm-10 control-label ">
                      Direccion
                   </label>
               <v-text-field
                    class="ma-2"
                    solo
                    id="address"
                    v-model="address"
                    v-validate="'required'"
                    name="address">
               </v-text-field>
            </v-flex>
              <v-flex xs12 sm4>
                <label class="col-sm-10 control-label ">
                  Correo Electronico
               </label>
                 <v-text-field
                    class="ma-2"
                    solo
                    id="email"
                    v-model="email"
                    v-validate="'required'"
                    name= "email">
                 </v-text-field>
              </v-flex>
            </v-layout>
                <!--Tabla con la lista de pasajeros creados-->


                <v-layout row>
               <v-btn color="primary" @click= "create" class="ma-2">
                   <v-icon medium class="mr-2">
                    mdi-content-save-check
                   </v-icon>
                   Salvar Hotel
               </v-btn>
                    <v-spacer></v-spacer>

            </v-layout>
      </v-container>
 </v-card>
    <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
</div>
</template>

<script>
import gql from 'graphql-tag'
import { CREATE_HOTEL_MUTATION } from '../constants/graphql'

export default {
    name: 'CreateHotel',
    apollo: {
    allCategories: gql`query {
    allCategories
      {
        id
        name
      }
     }`,
    allAirports: gql`query {
      allAirports {
        id
        name
        city
        country
        iataCode
      }
    }`,

  },
  data() {
        return {
            titulo:"AGREGAR",
                hotelName: '',
                address: '',
                email: '',
                phone:'',
                category:'',
                airport:'',
                loading:false,
               }
     },
  methods:{
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
    create(){
       this.createHotel(this.hotelName,this.address, this.airport, this.category, "", this.email, this.phone)
    },

    async createHotel(name,
                        address,
                        city,
                        category,
                        remarks,
                        email,
                        phone,){
        this.loading = true
        this.$apollo.mutate({
        mutation: CREATE_HOTEL_MUTATION,
        variables: {
            name,
                        address,
                        city,
                        category,
                        remarks,
                        email,
                        phone,
          }
        }).then((data) => {
              if(data){
                  console.log(data)
                  this.loading = false
                  this.$emit('hideform')
                  /*this.selectedPax.push = data.data.createPassenger.passenger.id
                  this.paxId = data.data.createPassenger.passenger.id
                  console.log(this.paxId)

                  this.addBagToList()*/
                  }
             }).catch((error) => {
              // Error
              this.loading = false
              alert("Este es el Error: "+error)
              // We restore the initial user input
            })
     }
    },



 }
</script>