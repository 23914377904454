<template>
    <div>
              <v-divider></v-divider>
            <v-list  nav dense>
                <v-list-item v-if="isAdmin" to="/sellerlist">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Usuarios</v-list-item-title>
                </v-list-item>
           <!--     <v-list-item v-if="isAdmin" to="/itineraryReport">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Reporte de Ventas</v-list-item-title>
                </v-list-item>
           -->
            <v-divider></v-divider>
               <v-list-item v-if="isAdmin" to="/fastItin">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-file-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Crear Itinerario</v-list-item-title>
                </v-list-item>
              <v-list-item v-else to="/sellerItin">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-file-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Crear Itinerario</v-list-item-title>
                </v-list-item>
            <v-divider></v-divider>
                 <v-list-item to="/itineraries" prepend-avatar="/adriitours-trasnparente-300x300.png">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-file-document</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Itinerarios</v-list-item-title>
                </v-list-item>
                 <v-list-item to="/allReservas">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-home-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Reservas</v-list-item-title>
                </v-list-item>
                 <v-list-item to="/waitinglist">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-seat-legroom-extra</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Lista de Espera</v-list-item-title>
                </v-list-item>
            </v-list>
    <v-divider></v-divider>
             <v-list nav dense>
                <v-list-item to="/paxs">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Pasajeros</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" to="/newFlights">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airplane-takeoff</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Vuelos</v-list-item-title>
                </v-list-item>
                 <v-list-item v-if="isAdmin" to="/allRutas">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airplane-settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Rutas</v-list-item-title>
                </v-list-item>
                 <v-list-item v-if="isAdmin" to="/amadeus">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airplane-settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Amadeus (beta)</v-list-item-title>
                </v-list-item>

            </v-list>
    <v-divider></v-divider>
        <v-list v-if="isAdmin" nav dense>
                <v-list-item to="/allHotels">
                    <v-list-item-icon>
                        <v-icon color="white" medium>mdi-bed</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Hoteles</v-list-item-title>
                </v-list-item>
                <v-list-item to="/allBags">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-bag-suitcase</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Equipajes</v-list-item-title>
                </v-list-item>
                     <v-list-item to="/allAirports">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airport</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Aeropuertos</v-list-item-title>
                </v-list-item>
               <v-list-item to="/allTerminals">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airplane-settings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Terminales</v-list-item-title>
                </v-list-item>
                <v-list-item to="/allAirlines">
                <v-list-item-icon>
                        <v-icon color="white" medium>mdi-airplane-landing</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Aerolineas</v-list-item-title>
                </v-list-item>
            </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
                <v-list-item @click="userSignOut">
                    <v-list-item-icon>
                        <v-icon color="red" medium>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title color="white" class="title">Salir</v-list-item-title>
                </v-list-item>
            </v-list>
    </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import {computed} from "vue";
export default {
  name: 'sideMenu',
    setup() {
        const userStore = useUserStore();
        const info  = computed(() => userStore.getUser);
        return { userStore, info};
      },
   data () {
    return {
        menu:'',
        groups: (JSON.parse(this.userStore.getUser)).groups || [],
        }
   },
   computed: {
       isAdmin(){
         if(this.groups)
          return this.groups.filter(group => group.id == 1).length > 0
         else
          return false
       }
   },
   async mounted () {
       //console.log("isAdmin: "+this.isAdmin)
    },
  methods:{
    userSignOut() {
      this.userStore.removeToken();
      this.userStore.removeUser();
      this.$router.push('/')
    },
  },
}
</script>
