<template>
  <div>
      <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
              :size="100"
              :width="15"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
      <v-card v-if="true">
    <v-card-title>
       <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

        <v-data-table
            :headers="headers"
            :items="airlinesList"
            :items-per-page="50"
            :search="search"
            class="elevation-1"
            :loading="loading"


        >
               <template v-slot:item.actions="{ item }">
                    <div v-if="item.active== 'Y'">
                      <v-icon
                        medium
                        class="mr-2"
                        color="green"
                      >
                      mdi-check-circle
                      </v-icon>
                     </div>
                     <div v-else>
                      <v-icon
                        medium
                        class="mr-2"
                        color="red"
                        @click="activate(item)"
                      >
                      mdi-cancel
                      </v-icon>
                     </div>
               </template>
   </v-data-table>
         </v-card>
  </div>
</template>

<script>
import { UPDATE_AIRLINE_MUTATION } from '../constants/graphql'
import { ALL_AIRLINESFULL } from '../constants/graphql'
export default {
  name: 'AirlinesList',
  data() {
      return {
            search: '',
            headers: [
          { text: 'Name', value: 'name' },
          { text: 'Codigo IATA', value: 'iata' },
          { text: 'Codigo ICAO', value: 'icao' },
          { text: 'Activar', value: 'actions' },
        ],
        airlinesList:[],
        flightInfo:'',
        loading:false,

     }
  },
  components: {
  },
  props: {
    airlines: {
      type: Array,
      required: false,
    },

    showAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch:{
    itineraries(n,o)
        {
            console.log(n.lenght+'>>'+o.lenght)
        }
  },
  computed: {
    /*loading(){
        if(this.airports.length>0)
            return false
         else
             return true
    },*/
    registeredBags () {
      return this.airports

      //return this.flights.filter(flights => flights.is_editable)
    },

  },
  async created (){

  },
  async mounted () {
     this.loadAllAirlines()
    },
  methods: {
   async loadAllAirlines(){
     this.loading = true
     await this.$apollo.query({
            query: ALL_AIRLINESFULL,
            fetchPolicy: "network-only",
            })
            .then((data)=>{
             this.airlinesList = data.data.allAirlinesFull
             this.loading = false
            })
   },
   activate: function(item) {
      if (confirm('Activar esta Aerolinea? ' + item.name)) {
          this.activarItem(item.id, "Y")
          item.active='Y'
       }
    },

    activarItem(airline,activa){
        this.$apollo.mutate({
        mutation: UPDATE_AIRLINE_MUTATION,
        variables: {
            airline,
            activa,
        }
        })
},


        goback: function(){if(confirm("Desea ir atras? Los datos no guardados se perderan.")) this.$router.go(-1)},
        gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},

        async addflight(){this.$router.push('/newflight')},

        async addEnvio(){


        },

       async addPaquete() {

       },

       update: function () {


        },

        async patch() {


        },


        async addProductos(){


            },

        getDestCount(){

            },

         getLocallista: function() {

        },

        getlista: function() {


        },



    },

  };

</script>

<style>
.post-list {
  list-style: none;
}

.post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.post__title {
  font-size: 1.25rem;
}

.post__description {
  color: #777;
  font-style: italic;
}

.post__tags {
  list-style: none;
  font-weight: bold;
  font-size: 0.8125rem;
}
</style>
