<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 align-center justify-center>
  <div>
    <v-app-bar
      color="blue darken-4"
      dark
      v-if="true"
    >
      <v-toolbar-title>Lista de Terminales</v-toolbar-title>
      <v-spacer></v-spacer>

       <v-spacer></v-spacer>
      <v-btn
       color="primary"
       @click= "gohome"
       >
       Inicio
       </v-btn>
        <v-spacer></v-spacer>
       <v-btn
       color="primary"
       @click= "addTerminal"
       ><v-icon
               medium
               class="mr-2"
               >
       mdi-plus
       </v-icon>
           {{buttonTag}}
       </v-btn>
   </v-app-bar>
    <CreateTerminal v-if = "createTerminal" @hideform = "hideCreateForm"/>
    <TerminalList v-if="showList" :equipajes="allEquipajes" key="equipajeKey"/>

  </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import {nextTick} from 'vue'
import TerminalList from '@/components/TerminalList'
import CreateTerminal from '@/components/CreateTerminal'

export default {
  name: 'AllTerminals',
  components: {
    TerminalList,
    CreateTerminal,
  },
  apollo: {
    allTerminals: gql`{
          allTerminals {
          id
          name
          aeropuerto{
                  id
                  name
                }
    }
   }`,
  },
  data () {
    return {
      createTerminal : false,
      buttonTag:"Agregar Terminal",
      terminalKey : 0,
      showList: true,

      }
   },
  methods:{
    goback: function(){
        if(confirm("Desea ir atras? Los datos no guardados se perderan."))
         {
           this.createTerminal = false
           this.buttonTag = "Agregar Terminal"
          }
      },
    gohome: function(){if(confirm("Desea cancelar ? Los datos no guardados se perderan.")) this.$router.push('/itineraries')},
   async  addTerminal(){
      await nextTick()
      this.terminalKey = this.terminalKey + 1
      this.createTerminal = !this.createTerminal
      this.showList = !this.showList
      if(this.createTerminal)
         this.buttonTag = "Mostrar Lista"
      else
         this.buttonTag = "Agregar Terminal"
   },
   hideCreateForm(){
      this.createTerminal = !this.createTerminal
      this.showList = !this.showList
      this.buttonTag = "Agregar Terminal"
   }
  },
}
</script>
